import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getAllSubClauses = () => {
  return Api(endPoints.subClause, {}, requestType.POST);
};

export const addSubClauses = (params) => {
  return Api(endPoints.addSubClause, params, requestType.POST);
};

export const updateSubClauses = (params) => {
  return Api(endPoints.updateSubClause, params, requestType.POST);
};

export const fetchISOReports = (params) => {
  let data = JSON.parse(localStorage.getItem("userData"));
  return Api(endPoints.fetchReportISO, params, requestType.POST, data.token);
};
