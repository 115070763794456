import React, { useState, forwardRef } from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { styled } from "@mui/system";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OrangeAlert = styled(MuiAlert)(({ theme }) => ({
  backgroundColor: "#FFA500",
  color: theme.palette.getContrastText("#FFA500"),
  '& .MuiAlert-icon': {
    color: theme.palette.getContrastText("#FFA500"),
  },
}));

const SnackbarAlert = forwardRef(({}, ref) => {
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  React.useImperativeHandle(ref, () => ({
    isOpenSnack(params) {
      setSnackbarProps({
        open: true,
        type: params.snackType,
        message: params.snackMessage,
      });
    },
    handleClose: handleCloseSnack
  }));

  const handleCloseSnack = () => {
    setSnackbarProps({
      open: false,
      type: "",
      message: "",
    });
  };

  return (
    <Snackbar
      open={snackbarProps.open}
      autoHideDuration={4000}
      onClose={handleCloseSnack}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      {snackbarProps.type === "success" ? (
        <OrangeAlert onClose={handleCloseSnack} severity={snackbarProps.type} sx={{ width: "100%" }}>
          {snackbarProps.message}
        </OrangeAlert>
      ) : (
        <Alert onClose={handleCloseSnack} severity={snackbarProps.type} sx={{ width: "100%" }}>
          {snackbarProps.message}
        </Alert>
      )}
    </Snackbar>
  );
});

export default SnackbarAlert;
