import { InputLabel } from "@mui/material";
import React from "react";
import useStyles from "../../globalStyles";

function CustomInputLabel({ label }) {
  const classes = useStyles();

  return <InputLabel className={classes.globalInputLabel}>{label}</InputLabel>;
}

export default CustomInputLabel;
