import { Button } from "@mui/material";
import React from "react";
import {
  allColors,
  primaryColor,
  secondaryColor,
} from "../../constants/colors";

const CustomButton = ({
  btnLabel,
  handlePressBtn,
  btnBgColor,
  btnTextColor,
  btnHoverColor,
  btnTextTransform,
  endIcon,
  textWeight,
  borderColor,
  variant,
  width,
  height,
  btnTextSize,
  borderRadius,
  styleProps,
  disabled
}) => {
  return (
    <Button
      sx={{
        borderColor: borderColor,
        width: width ? width : "auto",
        height: height ? height : "100%",
        background: btnBgColor ? btnBgColor : allColors.orangeGradient,
        backgroundColor: allColors.orange,
        color: btnTextColor ? btnTextColor : "#fff",
        fontWeight: textWeight ? textWeight : "400",
        borderRadius: borderRadius ? borderRadius : "6px",
        fontSize: btnTextSize ? btnTextSize : "14px",
        padding: "5px 15px",
        textTransform: btnTextTransform ? btnTextTransform : "capitalize",
        ...styleProps,
        "&:hover": {
          background: btnHoverColor ? btnHoverColor : allColors.orange,
          borderColor: borderColor,
        },
      }}
      disabled={disabled}
      onClick={handlePressBtn}
      endIcon={endIcon}
      variant={variant ? variant : ""}
    >
      {btnLabel}
    </Button>
  );
};

export default CustomButton;
