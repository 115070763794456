import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/customButton";
import CustomInputLabel from "../../../../components/customInputLabel";
import Loader from "../../../../components/loader";
import TextInput from "../../../../components/textInput";
import { constants } from "../../../../constants/variables";
import {
  addUpdateFinalReportDetails,
  fetchAuditReport,
  updateAuditStatus,
} from "../../../../services/auditors";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import {
  setCurrentClause,
  setStandards,
} from "../../../../redux/slices/allISO";

function CompletionForm() {
  const { userData } = useSelector((state) => state.Authentication);
  const receivedData = useLocation();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [address, setAddress] = useState([""]);
  const [inputVal, setInputVal] = useState({
    startDate: "",
    endDate: "",
    durationDays: "",
    reportAuthor: "",
    numberOfSites: 1,
    placeOfAudit: "",
    numberOfEmloyees: "",
    shiftPatterns: "",
    auditTeam: "",
    auditObjective: EditorState.createEmpty(),
    auditScope: EditorState.createEmpty(),
    scopeOfCertification: "",
    nonApplicableClause: EditorState.createEmpty(),
    clientRefId: "",
    AuditType: "",
    siteAddress: "",

    isGranted: false,
    isContinued: false,
    scopeExtension: false,
    isSuspended: false,
  });
  const { audit_status, id } = receivedData.state;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setCurrentClause({
        selectedPage: 0,
        selectedInd: 0,
        selectedContent: null,
      })
    );
    dispatch(setStandards([]));
    if (audit_status == "Assigned") {
      let params = {
        id,
        audit_status: "In-Progress",
      };
      updateAuditStatus(params)
        .then((resposne) => {})
        .catch((err) => {});
    }
    // fetchAllStandards(id);
    getDetail(id);
  }, []);

  const getHtmlContent = (valueeeee) => {
    const blocksFromHtml = htmlToDraft(valueeeee);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const getDetail = async (id) => {
    setIsLoading(true);
    try {
      let payloaddd = {
        company_audit_id: id,
      };
      let resulttt = await fetchAuditReport(payloaddd, userData.token);
      setIsLoading(false);
      let responseee = resulttt.data.data.auditReportDetails;
      if (responseee?.length > 0) {
        setInputVal({
          startDate: moment(
            responseee[0].audit_start_date,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"),
          endDate: moment(responseee[0].audit_end_date, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          clientRefId: responseee[0].client_reference,
          durationDays: responseee[0].audit_duration,
          reportAuthor: responseee[0].report_author,
          numberOfSites: responseee[0].number_of_sites,
          placeOfAudit: responseee[0].audit_activity_place,
          siteAddress: responseee[0].site_address,
          placeOfAudit: responseee[0].audit_activity_place,
          numberOfEmloyees: responseee[0].number_of_employees,
          shiftPatterns: responseee[0].shift_pattern,
          auditTeam: responseee[0].audit_team,
          auditObjective: getHtmlContent(responseee[0].audit_objective),
          auditScope: getHtmlContent(responseee[0].audit_summary),
          scopeOfCertification: responseee[0].shift_pattern,
          nonApplicableClause: getHtmlContent(
            responseee[0].non_applicable_clauses
          ),
        });
        setAddress(
          Array(
            Number(
              responseee[0].number_of_sites ? responseee[0].number_of_sites : 1
            )
          ).fill("")
        );
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "errorerrorerrorerror");
    }
  };

  const handleOnInputChange = (event, type) => {
    const { name, value } = event.target;
    if (type == "numberOfSites") {
      const numSites = parseInt(value);
      if (!isNaN(numSites) && numSites > 0) {
        setAddress(Array(numSites).fill(""));
      } else {
        setAddress([]);
      }
    }
    setInputVal({
      ...inputVal,
      [name]: value,
    });
  };

  const handleChangeDate = (event, name) => {
    setInputVal({
      ...inputVal,
      [name]: event.target.value,
    });
  };

  const handleSubmitData = async () => {
    if (inputVal.startDate == "") {
      constants.snackRef.isOpenSnack({
        snackType: "error",
        snackMessage: "Please select audit start date",
      });
    } else if (inputVal.endDate == "") {
      constants.snackRef.isOpenSnack({
        snackType: "error",
        snackMessage: "Please select audit end date",
      });
    } else if (inputVal.clientRefId == "") {
      constants.snackRef.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter client reference ID",
      });
    } else {
      const auditObjContent = draftToHtml(
        convertToRaw(inputVal.auditObjective.getCurrentContent())
      );
      const auditScopeContent = draftToHtml(
        convertToRaw(inputVal.auditScope.getCurrentContent())
      );

      const auditNonApplicableContent = draftToHtml(
        convertToRaw(inputVal.nonApplicableClause.getCurrentContent())
      );

      try {
        let payloadd = {
          company_audit_id: id,
          audit_objective: auditObjContent,
          audit_summary: auditScopeContent,
          audit_start_date: moment(inputVal.startDate).format("DD/MM/YYYY"),
          audit_end_date: moment(inputVal.endDate).format("DD/MM/YYYY"),
          audit_duration: inputVal.durationDays,
          report_author: inputVal.reportAuthor,
          audit_team: inputVal.auditTeam,
          number_of_sites: inputVal.numberOfSites,
          number_of_employees: inputVal.numberOfEmloyees,
          shift_pattern: inputVal.shiftPatterns,
          audit_activity_place: inputVal.placeOfAudit,
          scope_of_certification: inputVal.scopeOfCertification,
          non_applicable_clauses: auditNonApplicableContent,
          client_reference: inputVal.clientRefId,
          site_address: inputVal.siteAddress,
        };
        setIsLoading(true);
        let response = await addUpdateFinalReportDetails(
          payloadd,
          userData.token
        );
        if (response.status == 200) {
          getDetail();
          constants.snackRef.isOpenSnack({
            snackType: "success",
            snackMessage: "Audit report details saved successfully",
          });
          // navigate("/start-auditing", { state: receivedData.state });
          const { companyId } = state;
          navigate("/participant", { state: receivedData.state });
        } else {
          constants.snackRef.isOpenSnack({
            snackType: "error",
            snackMessage: response?.data?.message,
          });
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        constants.snackRef.isOpenSnack({
          snackType: "error",
          snackMessage: error.response?.data?.message,
        });
      }
    }
  };

  const renderCheckBox = (label, keyName, value) => {
    return (
      <FormControlLabel
        labelPlacement="top"
        control={
          <Checkbox
            name={keyName}
            onChange={(e) =>
              setInputVal({
                ...inputVal,
                [e.target.name]: e.target.checked,
              })
            }
            value={value}
            sx={{
              color: "orange",
              "&.Mui-checked": {
                color: "orange",
              },
            }}
          />
        }
        label={label}
      />
    );
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />
      <Typography variant={"h5"} fontSize={30} fontWeight={700} mb={2}>
        Audit Details
      </Typography>

      <Grid container spacing={2} alignItems={"flex-start"} mb={2}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Audit Type"} />
          <TextInput
            placeholder={"Audit Type"}
            value={inputVal.AuditType}
            name={"AuditType"}
            onChange={(event) => handleChangeDate(event, "AuditType")}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Audit Start Date"} />
          <TextInput
            type={"date"}
            value={inputVal.startDate}
            name={"startDate"}
            onChange={(event) => handleChangeDate(event, "startDate")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Audit End Date"} />
          <TextInput
            type={"date"}
            value={inputVal.endDate}
            name={"endDate"}
            onChange={(event) => handleChangeDate(event, "endDate")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Audit Duration Days"} />
          <TextInput
            placeholder={"Audit Duration Days"}
            value={inputVal.durationDays}
            name={"durationDays"}
            onChange={(event) => handleOnInputChange(event, "durationDays")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Report Author"} />
          <TextInput
            placeholder={"Report Author"}
            value={inputVal.reportAuthor}
            name={"reportAuthor"}
            onChange={(event) => handleOnInputChange(event, "reportAuthor")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Client Reference ID"} />
          <TextInput
            placeholder={"Client Reference ID"}
            value={inputVal.clientRefId}
            name={"clientRefId"}
            type="number"
            onChange={(event) => handleOnInputChange(event, "clientRefId")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Number Of Employees"} />
          <TextInput
            placeholder={"Number Of Employees"}
            value={inputVal.numberOfEmloyees}
            name={"numberOfEmloyees"}
            type="number"
            onChange={(event) => handleOnInputChange(event, "numberOfEmloyees")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Shift Pattern"} />
          <TextInput
            placeholder={"Shift Pattern :eg 9:00-17:00"}
            value={inputVal.shiftPatterns}
            name={"shiftPatterns"}
            onChange={(event) => handleOnInputChange(event, "shiftPatterns")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <CustomInputLabel label={"Scope of Certification"} />
          <TextInput
            placeholder={"Scope of Certification"}
            value={inputVal.scopeOfCertification}
            name={"scopeOfCertification"}
            type="text"
            onChange={(event) =>
              handleOnInputChange(event, "scopeOfCertification")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel
            label={
              "State the place of the audit activities (onsite or offsite, permanent or temporary sites included)"
            }
          />
          <TextInput
            placeholder={"Enter Audit Sites"}
            value={inputVal.placeOfAudit}
            name={"placeOfAudit"}
            type="number"
            onChange={(event) => handleOnInputChange(event, "placeOfAudit")}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel label={"Non Applicable Clause"} />
          <Editor
            editorState={inputVal.nonApplicableClause}
            editorStyle={{
              maxHeight: "350px",
              // overflow:"hidden",
              background: "#fff",
              padding: "6px 15px 60px",
            }}
            onEditorStateChange={(valllluee) => {
              setInputVal({
                ...inputVal,
                nonApplicableClause: valllluee,
              });
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "history",
                "colorPicker",
                "link",
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </Grid>
        <div
          style={{
            borderBottom: "1px solid black",
            height: "2px",
            width: "100%",
          }}
        />
        <Grid item xs={12}>
          <CustomInputLabel label={"Audit Objective"} />
          <Editor
            editorState={inputVal.auditObjective}
            editorStyle={{
              maxHeight: "350px",
              background: "#fff",
              padding: "6px 15px 60px",
            }}
            onEditorStateChange={(valllluee) => {
              setInputVal({
                ...inputVal,
                auditObjective: valllluee,
              });
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "history",
                "colorPicker",
                "link",
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </Grid>
        <div
          style={{
            borderBottom: "1px solid black",
            height: "2px",
            width: "100%",
          }}
        />
        <Grid item xs={12}>
          <CustomInputLabel label={"Audit Scope"} />
          <Editor
            editorState={inputVal.auditScope}
            editorStyle={{
              maxHeight: "350px",
              background: "#fff",
              padding: "6px 15px 60px",
            }}
            onEditorStateChange={(valllluee) => {
              setInputVal({
                ...inputVal,
                auditScope: valllluee,
              });
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "history",
                "colorPicker",
                "link",
              ],
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </Grid>
        <div
          style={{
            borderBottom: "1px solid black",
            height: "2px",
            width: "100%",
          }}
        />
        <Grid item xs={12}>
          <FormGroup row>
            {renderCheckBox("Granted", "isGranted")}
            {renderCheckBox("Continued", "isContinued")}
            {renderCheckBox("Updated for scope extension", "scopeExtension")}
            {renderCheckBox("Suspended", "isSuspended")}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInputLabel label={"Audit Team"} />
          <TextInput
            placeholder={"Audit Team"}
            value={inputVal.auditTeam}
            name={"auditTeam"}
            multiline
            onChange={(event) => handleOnInputChange(event, "auditTeam")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomInputLabel label={"Number Of Sites"} />
          <TextInput
            placeholder={"Number Of Sites"}
            value={inputVal.numberOfSites}
            name={"numberOfSites"}
            type="number"
            onChange={(event) => {
              let vallll = event.target.value;
              if (vallll == "-" || vallll == "+") {
                event.target.value = "";
              } else {
                handleOnInputChange(event, "numberOfSites");
              }
            }}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputLabel label={"Site Address"} />
          <TextInput
            placeholder={"Sites Address"}
            value={inputVal.siteAddress}
            name={"siteAddress"}
            onChange={(event) => handleOnInputChange(event, "siteAddress")}
            multiline
          />
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomButton
          btnLabel={"Add Multiple Site Address"}
          height={45}
          handlePressBtn={handleSubmitData}
        />
      </div>
      <div
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomButton
          btnLabel={"Next"}
          height={45}
          handlePressBtn={handleSubmitData}
        />
      </div>
    </NavigationDrawer>
  );
}

export default CompletionForm;
