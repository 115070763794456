import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/customButton";
import Loader from "../../../../components/loader";
import NavigationDrawer from "../../../../components/navigationDrawer";
import SnackbarAlert from "../../../../components/snackbar";
import { updateAuditStatus } from "../../../../services/auditors";

const data1 = [
  {
    question: "Confirmation of confidentiality",
    answer: "",
  },
  {
    question:
      "Introduction of the auditor/team including an outline of their roles",
    answer: "",
  },
  {
    question:
      "Confirmation of the audit plan (including type and scope of audit, objectives and criteria), any changes, and other relevant arrangements with the client, such as the date and time for the closing meeting, interim meetings between the audit team and the client’s management",
    answer: "",
  },
  {
    question: "Confirmation on the scope of certification",
    answer: "",
  },
  {
    question:
      "Confirmation on the scope of certification Confirmation of formal communication channels between the audit team and the client",
    answer: "",
  },
  {
    question:
      "Confirmation that resources and facilities needed by the audit team are available",
    answer: "",
  },
  {
    question:
      "Confirmation of relevant work safety, emergency and security procedures for the audit team",
    answer: "",
  },
  {
    question:
      "Confirm Impartiality: The audit team and client confirm there are no associated conflicts of interest",
    answer: "",
  },
  {
    question:
      "Confirmation of the availability, roles and identities of any guides, observers, technical experts as applicable (Note, a technical expert shall not act as an auditor in the audit team)",
    answer: "",
  },
  {
    question:
      "Explain method of audit reporting and explain the findings i.e. Major non-conformity – total system failure Minor Non-Conformity – Single lapse OFI – Opportunity for improvement",
    answer: "",
  },
  {
    question:
      "Inform the client about the conditions under which the audit may be prematurely terminated – e.g. Health and Safety Concern, if the auditor identifies multiple situations of non-conformity, system in decline, system not ready for stage 2, situations of statutory and regulatory",
    answer: "",
  },
  {
    question:
      "Confirmation that the audit team leader and audit team representing the certification body is responsible for the audit and shall be in control of executing the audit plan including audit activities and audit trails",
    answer: "",
  },
  {
    question:
      "Methods and procedures to be used to conduct the audit based on sampling",
    answer: "",
  },
  {
    question: "Confirmation of the language to be used during the audit",
    answer: "",
  },
  {
    question:
      "Confirmation that, during the audit, the client will be kept informed of audit progress and any concerns",
    answer: "",
  },
  {
    question: "Opportunity for the client to ask questions",
    answer: "",
  },
];
const data2 = [
  {
    question: "Confirmation of confidentiality",
    answer: "",
  },
  {
    question:
      "Explain the method and timeframe of reporting, including any grading of audit findings",
    answer: "",
  },
  {
    question:
      "Advise the client that the audit evidence obtained was based on a sample of the information; thereby introducing an element of uncertainty",
    answer: "",
  },
  {
    question:
      "Explain the ForeFront post audit activities, e.g. audit report technical review",
    answer: "",
  },
  {
    question:
      "The ForeFront process for handling nonconformities, including any consequences relating to the status of the client’s certification",
    answer: "",
  },
  {
    question:
      "The timeframe for the client to present a plan for correction and corrective action for any nonconformities identified during the audit",
    answer: "",
  },
  {
    question: "Information about the complaint and appeal handling processes",
    answer: "",
  },
  {
    question:
      "Confirm Impartiality: The audit team and client confirm there are no associated conflicts of interest",
    answer: "",
  },
  {
    question:
      "Confirm the appropriateness of the audit programme and identify any modification required for future audits (e.g. scope of certification, audit time or dates, audit frequency)",
    answer: "",
  },
  {
    question: "Opportunity for the client to ask questions",
    answer: "",
  },
];

const MeetingAgenda = () => {
  const { userData } = useSelector((state) => state.Authentication);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    surveillance_one_days: "",
    surveillance_one_date: "",
    surveillance_two_days: "",
    surveillance_two_date: "",
    recert_days: "",
    recert_date: "",
    planID: null,
  });
  const [openData, setOpenData] = useState(data1);
  const [closeData, setCloseData] = useState(data2);
  const snackRef = useRef();

  const handleCheckboxChange = (index, field) => {
    const newData = [...data];
    newData[index][field] = !newData[index][field];
    setData(newData);
  };

  const handleOnInputChange = (event, type) => {
    const { name, value } = event.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const completeAudit = async () => {
    let companyDetail = JSON.parse(localStorage.getItem("companyDetail"));
    let params = {
      id: state.companyId,
      audit_status: "Completed",
      audit_type: companyDetail.audit_type,
    };
    let result = await updateAuditStatus(params);
    if (result.status == 200) {
      snackRef.current.isOpenSnack({
        snackType: "success",
        snackMessage: "Audit Completed Successfully",
      });
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } else {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: result.data.message,
      });
    }
  };

  const handleChangeOpen = (e, indddd) => {
    let tempArr = [...openData];
    tempArr[indddd].answer = e.target.value;
    setOpenData(tempArr);
  };
  const handleChangeClose = (e, indddd) => {
    let tempArr = [...closeData];
    tempArr[indddd].answer = e.target.value;
    setCloseData(tempArr);
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />

      <Box style={{ display: "flex", alignItems: "center" }} mb={3}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginRight: "12px" }}
        />
        <Typography variant="h5" fontSize={{ xs: 20, sm: 30 }} fontWeight={700}>
          Appendix A - Opening and Closing meeting agenda
        </Typography>
      </Box>

      <Typography variant="h6" fontSize={25} fontWeight={700} mt={3}>
        Opening Meeting Agenda
      </Typography>

      {openData.map((itemmmmm, indddd) => {
        return (
          <Grid container spacing={2} mt={2} key={indddd}>
            <Grid item xs={12} sm={10}>
              <Typography sx={{ fontWeight: "600" }}>
                {itemmmmm.question}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={itemmmmm.answer}
                  name="radio-buttons-group"
                  onChange={(e) => handleChangeOpen(e, indddd)}
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        sx={{
                          color: "orange",
                          "&.Mui-checked": {
                            color: "orange",
                          },
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={
                      <Radio
                        sx={{
                          color: "orange",
                          "&.Mui-checked": {
                            color: "orange",
                          },
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      })}

      <Typography variant="h6" fontSize={25} fontWeight={700} mt={3}>
        Closing Meeting Agenda
      </Typography>

      {closeData.map((itemmmmm, indddd) => {
        return (
          <Grid container spacing={2} mt={2} key={indddd}>
            <Grid item xs={12} ms={10}>
              <Typography sx={{ fontWeight: "600" }}>
                {itemmmmm.question}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={itemmmmm.answer}
                  name="radio-buttons-group"
                  onChange={(e) => handleChangeClose(e, indddd)}
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={
                      <Radio
                        sx={{
                          color: "orange",
                          "&.Mui-checked": {
                            color: "orange",
                          },
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={
                      <Radio
                        sx={{
                          color: "orange",
                          "&.Mui-checked": {
                            color: "orange",
                          },
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      })}

      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <CustomButton
          btnLabel={"Complete Audit"}
          // handlePressBtn={handleAuditPlan}
        />
      </div>
      <SnackbarAlert ref={snackRef} />
    </NavigationDrawer>
  );
};

export default MeetingAgenda;
