import { Box, DialogContent, Grid, MenuItem, Typography } from "@mui/material";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import BootstrapDialog from "../../../../components/dialog/index";
import CustomDropDown from "../../../../components/customDropDown";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import { assignCompanyToAuditor } from "../../../../services/assignCompanyToAuditor";
import SnackbarAlert from "../../../../components/snackbar";
import Loader from "../../../../components/loader";

let paramsData = {};

const AddEditAssingDialog = forwardRef(({ allCompanies, allAuditors }, ref) => {
  const snackRef = useRef();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputVal, setInputVal] = useState({
    companyId: "",
    auditorId: "",
  });

  const handleOpenSnack = (type, message) => {
    snackRef.current.isOpenSnack({
      snackType: type,
      snackMessage: message,
    });
  };

  const handleChangeInputs = (e, name) => {
    const { value } = e.target;
    setInputVal((pre) => ({ ...pre, [name]: value }));
  };

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      paramsData = params;
      setOpen(true);
      if (params.type === "edit") {
        setInputVal({
          companyId: params.data.company_id,
          auditorId: params.data.auditor_id,
        });
      }
    },
  }));

  const onclosePress = () => {
    setOpen(false);
    setInputVal({});
  };

  const handleAdd = async () => {
    const { companyId, auditorId } = inputVal;
    try {
      if (!companyId) {
        return handleOpenSnack("error", "Company ID is required");
      }
      if (!auditorId) {
        return handleOpenSnack("error", "Auditor ID is required");
      }

      let payload = {
        company_id: companyId,
        auditor_id: auditorId,
      };

      setIsLoading(true);
      const response = await assignCompanyToAuditor(payload);
      setIsLoading(false);
      if (response.status === 200 || response.status === 200) {
        handleOpenSnack("success", "Assingn company to auditor successfully");
        paramsData.getAllData();
        onclosePress();
      } else {
        handleOpenSnack("error", response.data.message);
      }
    } catch (error) {
      handleOpenSnack("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Loader isloading={isLoading} />
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader
          title={
            paramsData.type === "add"
              ? "Add Assign Company To Auditor"
              : "Edit Assign Company To Auditor"
          }
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomDropDown
                fieldLabel={"Select Company"}
                selectedValue={inputVal.companyId}
                fieldName={"companyId"}
                handleChange={handleChangeInputs}
              >
                <MenuItem value={""}>Select Company</MenuItem>
                {allCompanies?.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={12}>
              <CustomDropDown
                fieldLabel={"Select Auditor"}
                selectedValue={inputVal.auditorId}
                fieldName={"auditorId"}
                handleChange={handleChangeInputs}
              >
                <MenuItem value={""}>Select Auditor</MenuItem>
                {allAuditors?.map((item) => {
                  return (
                    <MenuItem
                      value={item.id}
                    >{`${item.first_name} ${item.last_name}`}</MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={paramsData.type}
          handleCreate={handleAdd}
          // handleUpdate={handleSave}
          handleClose={onclosePress}
        />
      </BootstrapDialog>
    </Box>
  );
});

export default AddEditAssingDialog;
