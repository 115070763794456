import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../../components/loader";
import NavigationDrawer from "../../../../components/navigationDrawer";
import TableComponent from "../../../../components/Table";
import { setStandards } from "../../../../redux/slices/allISO";
import { updateAuditStatus } from "../../../../services/auditors";
import { getAllStandards } from "../../../../services/isoStandards";
import { fetchISOBySubClause } from "../../../../services/linkIsotoSubClause";
import { fetchISOReports } from "../../../../services/subClauses";
import { useCallback } from "react";
import debounce from "lodash/debounce";

const Auditing = () => {
  const handleResize = debounce(() => {
    // Your resize logic here
  }, 100); //

  const resizeObserver = useCallback((node) => {
    if (node) {
      const observer = new ResizeObserver(() => {
        handleResize();
      });
      observer.observe(node);
      return () => observer.disconnect();
    }
  }, []);

  const receivedData = useLocation();
  const { allStandards } = useSelector((data) => data.allISO);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedISO, setSelectedISO] = useState([]);
  const [isoStandards, setIsoStandards] = useState([]);
  const [mainData, setMainData] = useState([]);
  const { audit_status, id } = receivedData.state;
  const dispatch = useDispatch();
  useEffect(() => {
    if (audit_status == "Assigned") {
      let params = {
        id,
        audit_status: "In-Progress",
      };
      updateAuditStatus(params)
        .then((resposne) => {})
        .catch((err) => {});
    }
    fetchAllStandards(id);
  }, []);

  const fetchAllStandards = async (id) => {
    setIsLoading(true);
    try {
      const response = await getAllStandards();
      let newArrrrr = response.data.data.isoStandards.map((itemmmmm) => {
        return {
          label: itemmmmm.name,
          value: itemmmmm.id,
        };
      });
      setIsoStandards(newArrrrr);
      let params = {
        company_audit_id: id,
      };
      fetchISOReports(params)
        .then((innerResss) => {
          let arr = innerResss.data.data.isoStandards.map((item) => {
            return item.iso_standard_id;
          });
          let currentArray = innerResss.data.data.isoStandards.map(
            (itemmmmm) => {
              return {
                label: itemmmmm.iso_name,
                value: itemmmmm.iso_standard_id,
              };
            }
          );
          setSelectedISO(currentArray);
          fetchClauseData(currentArray, arr);
        })
        .catch((err) => {
          console.log(err, "ERRRRRRRRRRRRR");
        });
    } catch (err) {
      console.error("Error fetching companies:", err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClauseData = (selectedItem, payArrrr) => {
    fetchISOBySubClause({
      iso_list: payArrrr,
    })
      .then((response) => {
        let tempArr = [];
        setMainData(response.data.data.isoSubClauses);
        response.data.data.isoSubClauses.map((item) => {
          let newObj = {
            clauseID: "",
            clauseNumber: "",
            clauseName: "",
            data: [],
          };
          let index = tempArr.findIndex(
            (data) => data.clauseID == item.clause_id
          );
          if (index == -1) {
            let ressss = selectedItem.find((stan) => stan.value == item.iso_id);
            newObj.clauseID = item.clause_id;
            newObj.clauseName = item.clause_title;
            newObj.clauseNumber = item.clause_number;
            newObj.data = [item];
            tempArr.push(newObj);
          } else {
            let resultttt = tempArr[index].data.find(
              (vallllllll) =>
                vallllllll.sub_clause_number == item.sub_clause_number
            );
            if (resultttt == undefined) {
              tempArr[index].data.push(item);
            }
          }
        });
        dispatch(setStandards(tempArr));
      })
      .catch((err) => {});
  };

  const handleChange = (selectedItem) => {
    setSelectedISO(selectedItem);
    let payArrrr = selectedItem.map((vall) => {
      return vall.value;
    });
    fetchClauseData(selectedItem, payArrrr);
  };

  return (
    <div ref={resizeObserver}>
      <NavigationDrawer>
        <Loader isloading={isLoading} />
        <Typography
          variant={"h5"}
          sx={{ marginTop: "2em" }}
          fontSize={30}
          fontWeight={700}
          mb={2}
        >
          Start Auditing
        </Typography>
        <Grid container spacing={2} alignItems="flex-end" mb={2}>
          <Grid item xs={12} md={4}>
            <Select
              defaultValue={selectedISO}
              value={selectedISO}
              onChange={(vallll) => handleChange(vallll)}
              options={isoStandards}
              placeholder="Please Select ISO Standards"
              isMulti
            />
            {/* <CustomDropDown
            selectedValue={selectedISO}
            fieldLabel={"Please Select ISO Standards"}
            handleChange={handleChange}
            fieldName={"IsoID"}
          >
            {isoStandard.map((item) => {
              return <MenuItem value={item.id}>{item.name}</MenuItem>;
            })}
          </CustomDropDown> */}
          </Grid>
        </Grid>
        <Grid spacing={2} style={{ marginTop: 10 }}>
          <TableComponent
            data={allStandards}
            isoStandards={selectedISO}
            mainData={mainData}
            companyId={id}
          />
        </Grid>
      </NavigationDrawer>
    </div>
  );
};

export default Auditing;
