import { Box, DialogContent, Typography } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import DialogHeader from "./dialogHeader";
import BootstrapDialog from ".";

let paramsData = {};

const DescriptionDialog = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      paramsData = params;
      setOpen(true);
    },
  }));

  const onclosePress = () => {
    setOpen(false);
  };

  return (
    <Box>
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader title={"Description"} handleClose={onclosePress} />
        <DialogContent dividers>
          <Typography>{paramsData?.value}</Typography>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
});

export default DescriptionDialog;
