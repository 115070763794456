import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";
import HtmlParser from "../../../../components/htmlParser/htmlParser";

const PageSeven = ({ titleeee, valIndex, summaryItem }) => (
  <Page size={"A4"}>
    <PDFHeaderFooter>
      {valIndex == 1 && (
        <Text
          style={{
            textAlign: "left",
            width: "110%",
            fontSize: 17,
            fontWeight: "700",
            marginLeft:'180px'
          }}
        >
          {titleeee}
        </Text>
      )}
      <View
        style={{
          border: "1px solid black",
          width: "80%",
          alignSelf: "center",
          marginTop: "6px",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid black",
          }}
        >
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "900",
              borderRight: "1px solid black",
              paddingTop: "2px",
              width: "40%",
              paddingHorizontal: 2,
            }}
          >
            Finding Ref:
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                width: "30%",
                borderRight: "1px solid black",
                paddingTop: "2px",
                paddingLeft: "5px",
              }}
            >
              {valIndex}
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "900",
                flex: 1,
                borderRight: "1px solid black",
                paddingTop: "2px",
                paddingLeft: "5px",
              }}
            >
              Clause Reference:
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "900",
                paddingTop: "2px",
                paddingLeft: "5px",
                width: "33%",
              }}
            >
              {summaryItem.sub_clause_number}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottom: "1px solid black",
          }}
        >
          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              borderRight: "1px solid black",
              width: "40%",
            }}
          >
            <Text style={{ fontSize: "11px", fontWeight: "900" }}>
              Applicable Standard(s):
            </Text>
          </View>

          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              flex: 1,
            }}
          >
            <Text style={{ fontSize: "10px", fontWeight: "700" }}>
              {summaryItem.iso_standard_name}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottom: "1px solid black",
          }}
        >
          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              borderRight: "1px solid black",
              width: "40%",
            }}
          >
            <Text style={{ fontSize: "11px", fontWeight: "900" }}>Area:</Text>
          </View>

          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              flex: 1,
            }}
          >
            <Text style={{ fontSize: "10px", fontWeight: "700" }}>
              {summaryItem.sub_clause_title}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              borderRight: "1px solid black",
              width: "40%",
            }}
          >
            <Text style={{ fontSize: "11px", fontWeight: "900" }}>
              Clause Requirement:
            </Text>
          </View>

          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              flex: 1,
            }}
          >
            <Text style={{ fontSize: "10px", fontWeight: "700" }}>
              <HtmlParser html={summaryItem.description} />
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottom: "1px solid black",
            borderTop: "1px solid black",
          }}
        >
          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              borderRight: "1px solid black",
              width: "40%",
            }}
          >
            <Text style={{ fontSize: "11px", fontWeight: "900" }}>
              Action & Objective
            </Text>
          </View>

          <View
            style={{
              paddingHorizontal: 2,
              paddingVertical: 1,
              flex: 1,
            }}
          >
            <Text style={{ fontSize: "10px", fontWeight: "700" }}>
              Action: {summaryItem.comments ? summaryItem.comments : "-"}
            </Text>
            <Text style={{ fontSize: "10px", fontWeight: "700" }}>
              Objective:{" "}
              {summaryItem.objective_evidence
                ? summaryItem.objective_evidence
                : "-"}
            </Text>
          </View>
        </View>
      </View>
    </PDFHeaderFooter>
  </Page>
);

export default PageSeven;
