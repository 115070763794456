import { Box, Typography } from "@mui/material";
import NavigationDrawer from "../../../../components/navigationDrawer";
import CustomButton from "../../../../components/customButton";
import { useEffect, useRef, useState } from "react";
import AddEditAssingDialog from "./AddEditAssignDialog";
import { getAllCompanies } from "../../../../services/company";
import { getAllAuditors } from "../../../../services/auditors";
import { fetchAllAuditorList } from "../../../../services/assignCompanyToAuditor";
import DynamicTable from "../../../../components/dynamicTable";
import DescriptionDialog from "../../../../components/dialog/descriptionDialog";
import Loader from "../../../../components/loader";

const headerData = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: "Company Name",
  },
  {
    id: "auditorName",
    numeric: false,
    disablePadding: true,
    label: "Auditor Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Auditor Email",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    align:"center"
  },
];

const displayRows = [
  "id",
  "company_name",
  "auditor_fullname",
  "email",
  "actions",
];

const AssignCompanyToAuditor = () => {
  const openDialog = useRef(null);
  const openDesDialog = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allAuditors, setAllAuditors] = useState([]);
  const [allAuditorsList, setAllAuditorList] = useState([]);

  useEffect(() => {
    fetchAllData();
    getAllAuditorList();
  }, []);

  const fetchAllData = async () => {
    try {
      const [companiesResponse, auditorsResponse] = await Promise.all([
        getAllCompanies(),
        getAllAuditors(),
      ]);
      setAllCompanies(companiesResponse.data.data.companies);
      setAllAuditors(auditorsResponse.data.data.users);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllAuditorList = async () => {
    setIsLoading(true);
    try {
      const response = await fetchAllAuditorList();
      setAllAuditorList(response.data.data.companyAuditor);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = () => {
    openDialog.current.isVisible({
      type: "add",
      data: {},
      getAllData: getAllAuditorList,
    });
  };

  const handleEditDialog = (row) => {
    openDialog.current.isVisible({
      type: "edit",
      data: row,
      getAllData: getAllAuditorList,
    });
  };

  const handleOpenDesDialog = (row) => {
    openDesDialog.current.isVisible({ value: row.company_description });
  };

  return (
    <NavigationDrawer>
      <Loader isloading={isLoading} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
        mt={"5em"}
        mb={4}
      >
        <Typography variant="h5" fontSize={30} fontWeight={700}>
          Assign Company to Auditor
        </Typography>
        <Box
          height={40}
        >
          <CustomButton
            btnLabel="Assign Auditor"
            handlePressBtn={() => handleOpenDialog("add")}
          />
        </Box>
      </Box>
      <AddEditAssingDialog
        ref={openDialog}
        allCompanies={allCompanies}
        allAuditors={allAuditors}
      />

      <DynamicTable
        showEdit
        headerData={headerData}
        bodyRows={allAuditorsList}
        displayData={displayRows}
        handlePressView={handleOpenDesDialog}
        openEditDialog={handleEditDialog}
      />
      <DescriptionDialog ref={openDesDialog} />
    </NavigationDrawer>
  );
};

export default AssignCompanyToAuditor;
