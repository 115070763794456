import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { images } from "../../assets";
import { setUserData } from "../../redux/slices/Authentication";
import { setDrawerOpen } from "../../redux/slices/DrawerToggle";
import { AuditorRoutes, SuperAdminRoutes } from "../../routes";
import useStyles from "./style";
import { CustomAppBar, CustomDrawer, DrawerHeader } from "./styledComponents";
import {
  setClauseClick,
  setCurrentClause,
  setStandards,
} from "../../redux/slices/allISO";

export default function NavigationDrawer(props) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.Authentication);
  const { openDrawer } = useSelector((state) => state.DrawerToggle);
  const {
    clauseClick,
    allStandards,
    selectedInd,
    selectedContent,
    selectedPage,
  } = useSelector((data) => data.allISO);
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateLink = (route, name) => {
    localStorage.setItem("toggle_drawer", openDrawer);
    navigate(`/${route}`);
    dispatch(
      setCurrentClause({
        selectedPage: 0,
        selectedInd: 0,
        selectedContent: null,
      })
    );
    dispatch(setStandards([]));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomAppBar position="fixed" open={openDrawer}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              color: "black",
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}

          <div>
            <ListItem
              className={classes.profileHeader}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              style={{ cursor: "pointer", paddingRight: 0 }}
            >
              <ListItemText
                primary={userData?.first_name}
                style={{ textTransform: "capitalize", marginRight: "10px" }}
              />
              <ListItemAvatar>
                <Avatar style={{ textTransform: "capitalize" }}>
                  {userData?.first_name?.charAt(0)}
                </Avatar>
              </ListItemAvatar>
            </ListItem>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleClose();
                  localStorage.clear();
                  navigate("/login");
                  dispatch(setUserData(null));
                  // window.location.pathname = "/login";
                }}
                className={classes.menuItemStyle}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </CustomAppBar>
      <CustomDrawer variant="permanent" open={openDrawer}>
        <DrawerHeader
          style={{
            // minHeight: "42px",
            position: "relative",
            // backgroundColor: openDrawer ? primaryColor : "#fff",
            justifyContent: "flex-start",
            padding: "10px",
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              display: {
                xs: "block",
                md: "none",
                position: "absolute",
                top: "5px",
                right: "5px",
              },
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon
                style={{
                  background: "#fff",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
              />
            ) : (
              <ChevronLeftIcon
                style={{
                  background: "#fff",
                  borderRadius: "50%",
                  fontSize: "20px",
                }}
              />
            )}
          </IconButton>
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              width: "85%",
              height: "35px",
              alignSelf: "center",
              margin: "auto",
            }}
          >
            <img src={images.webLogoImg} height={"100%"} width={"100%"} />
          </Box>
        </DrawerHeader>
        <Divider />
        <List>
          {userData?.role_name == "admin" ? (
            SuperAdminRoutes?.map((route) => {
              return (
                <ListItem
                  key={route.id}
                  onClick={() => navigateLink(route.path, route.name)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      location.pathname == `/${route.path}`
                        ? "#f06637"
                        : "inherit",
                    "&:hover": {
                      backgroundColor: "#ffede6",
                      "& > *": {
                        color: "black",
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "35px",
                      color:
                        location.pathname == `/${route.path}`
                          ? "white"
                          : "black",
                    }}
                    className=""
                  >
                    <Icon>{route.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color:
                        location.pathname == `/${route.path}`
                          ? "white"
                          : "black",
                      display: openDrawer ? "block" : "none",
                      "& .MuiListItemText-primary": {
                        fontSize: "0.875rem",
                        whiteSpace: "break-spaces",
                      },
                    }}
                    primary={route.name}
                  />
                </ListItem>
              );
            })
          ) : userData?.role_name == "auditor" ? (
            <>
              {AuditorRoutes?.map((route) => {
                if (route.name != "") {
                  return (
                    <ListItem
                      button
                      key={route.id}
                      onClick={() => navigateLink(route.path, route.name)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor:
                          location.pathname == `/${route.path}`
                            ? "#f06637"
                            : "inherit",
                        "&:hover": {
                          backgroundColor: "#ffede6",
                          "& > *": {
                            color: "black",
                          },
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "35px",
                          color:
                            location.pathname == `/${route.path}`
                              ? "white"
                              : "black",
                        }}
                        className=""
                      >
                        <Icon>{route.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={route.name}
                        sx={{
                          color:
                            location.pathname == `/${route.path}`
                              ? "white"
                              : "black",
                          display: openDrawer ? "block" : "none",
                          "& .MuiListItemText-primary": {
                            fontSize: "0.875rem",
                            whiteSpace: "break-spaces",
                          },
                        }}
                      />
                    </ListItem>
                  );
                }
              })}
              {allStandards?.map((route, mainInddd) => {
                return (
                  <>
                    <ListItem
                      key={route.id}
                      // onClick={() => navigateLink(route.path, route.name)}
                      sx={{
                        paddingBottom: 0,
                        // backgroundColor:
                        //   route.clauseID == selectedContent?.clause_id
                        //     ? "#f06637"
                        //     : "inherit",
                      }}
                    >
                      <ListItemText
                        primary={`Clause: ${route.clauseNumber} (${route.clauseName})`}
                        sx={{
                          color:
                            location.pathname == `/${route.path}`
                              ? "white"
                              : "black",
                          display: openDrawer ? "block" : "none",
                          "& .MuiListItemText-primary": {
                            fontSize: "0.675rem",
                            whiteSpace: "break-spaces",
                            fontWeight: "700",
                          },
                        }}
                      />
                    </ListItem>
                    {route.data.map((item, indddd) => {
                      return (
                        <ListItem
                          onClick={() => {
                            dispatch(
                              setCurrentClause({
                                selectedPage: mainInddd,
                                selectedInd: indddd,
                                selectedContent: item,
                              })
                            );
                            dispatch(setClauseClick(!clauseClick));
                          }}
                          sx={{
                            marginLeft: "10px",
                            padding: "7px 10px",
                            cursor: "pointer",
                            flex: 1,
                            alignItems: "flex-start",
                            textWrap: "wrap",
                            backgroundColor:
                              item.sub_clause_number ==
                              selectedContent?.sub_clause_number
                                ? "#ffede6"
                                : "none",
                            "&:hover": {
                              backgroundColor: "#ffede6",
                              "& > *": {
                                color: "black",
                              },
                            },
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              marginRight: "5px",
                              fontWeight:
                                item.sub_clause_number ==
                                selectedContent?.sub_clause_number
                                  ? "700"
                                  : "500",
                            }}
                          >
                            {item.sub_clause_number}
                          </span>
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontWeight:
                                item.sub_clause_number ==
                                selectedContent?.sub_clause_number
                                  ? "700"
                                  : "500",
                              overflowWrap: "break-word",
                              display: openDrawer ? "block" : "none",
                            }}
                          >
                            {item.sub_clause_title}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </>
                );
              })}
            </>
          ) : null}
        </List>
      </CustomDrawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // backgroundColor: "#f5f6f8",
          height: "100vh",
          overflowX: "auto",
        }}
      >
        <div style={{ marginBottom: "50px" }}></div>
        {props.children}
      </Box>
    </Box>
  );
}
