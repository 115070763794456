import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import Authentication from "./slices/Authentication";
import DrawerToggle from "./slices/DrawerToggle";
import allISO from "./slices/allISO";

const reducer = combineReducers({
  Authentication,
  DrawerToggle,
  allISO,
});

const store = configureStore({ reducer });

export default store;
