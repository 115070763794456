import React from "react";
import Companies from "../containers/app/superAdminScreens/companies";
import Auditors from "../containers/app/superAdminScreens/auditors";
import AuditorProfile from "../containers/app/auditorScreens/auditorProfile";
// import DashBoard from "../../containers/app/adminScreens/dashboard";
import Login from "./../containers/auth/login/index";
import ISOStandard from "../containers/app/superAdminScreens/isoStandards";
import Clauses from "../containers/app/superAdminScreens/clauses";
import SubClauses from "../containers/app/superAdminScreens/subClauses";
import LinkISOToSubClause from "../containers/app/superAdminScreens/linkISOtoSubClause";
import Auditing from "../containers/app/auditorScreens/auditing";
import AuditCompanies from "../containers/app/auditorScreens/auditCompanies";
import AssignCompanyToAuditor from "../containers/app/superAdminScreens/assignCompanyToAuditor";
import CompletionForm from "../containers/app/auditorScreens/completionForm";
import Participants from "../containers/app/auditorScreens/participants";
import BusinessIcon from "@mui/icons-material/Business";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import NextAuditPlan from "../containers/app/auditorScreens/nextAuditPlan";
import OhsParticipants from "../containers/app/auditorScreens/ohsParticipants";
import NextSteps from "../containers/app/auditorScreens/nextSteps";
import ThreeYearPlan from "../containers/app/auditorScreens/threeYearPlan";
import MeetingAgenda from "../containers/app/auditorScreens/meetingAgenda";
const SuperAdminRoutes = [
  // {
  //   id: 1,
  //   name: "Dashboard",
  //   component: <DashBoard />,
  //   exact: "exact",
  //   path: "",
  //   icon: "dashboard",
  // },
  {
    id: 1,
    name: "Companies",
    component: <Companies />,
    exact: "exact",
    path: "",
    icon: <BusinessIcon />,
  },
  {
    id: 2,
    name: "Auditors",
    component: <Auditors />,
    exact: "exact",
    path: "auditors",
    icon: <LibraryBooksIcon />,
  },
  {
    id: 3,
    name: "ISO Standards",
    component: <ISOStandard />,
    exact: "exact",
    path: "iso-standards",
    icon: <SupervisedUserCircleIcon />,
  },
  {
    id: 4,
    name: "Clauses",
    component: <Clauses />,
    exact: "exact",
    path: "clauses",
    icon: <EditNoteIcon />,
  },
  {
    id: 5,
    name: "Sub Clauses",
    component: <SubClauses />,
    exact: "exact",
    path: "subclauses",
    icon: <EditNoteIcon />,
  },
  {
    id: 6,
    name: "Link ISO - Sub Clause",
    component: <LinkISOToSubClause />,
    exact: "exact",
    path: "link-iso",
    icon: <SupervisorAccountIcon />,
  },
  {
    id: 7,
    name: "Assign Company To Auditor",
    component: <AssignCompanyToAuditor />,
    exact: "exact",
    path: "assign-company-to-auditor",
    icon: <AssignmentTurnedInIcon />,
  },
];

const AuditorRoutes = [
  {
    id: 1,
    name: "Companies",
    component: <AuditCompanies />,
    exact: "exact",
    path: "",
    icon: <BusinessIcon />,
  },
  {
    id: 2,
    name: "",
    component: <Auditing />,
    exact: "exact",
    path: "/start-auditing",
    icon: <LibraryBooksIcon />,
  },
  {
    id: 2,
    name: "",
    component: <CompletionForm />,
    exact: "exact",
    path: "/audit-form",
    icon: "person",
  },
  {
    id: 2,
    name: "",
    component: <Participants />,
    exact: "exact",
    path: "/participant",
    icon: "person",
  },
  {
    id: 2,
    name: "",
    component: <NextAuditPlan />,
    exact: "exact",
    path: "/next-plan",
    icon: "person",
  },
  {
    id: 2,
    name: "",
    component: <OhsParticipants />,
    exact: "exact",
    path: "/ohs-participants",
    icon: "person",
  },
  {
    id: 2,
    name: "",
    component: <NextSteps />,
    exact: "exact",
    path: "/next-steps",
    icon: "person",
  },
  {
    id: 2,
    name: "",
    component: <ThreeYearPlan />,
    exact: "exact",
    path: "/three-year-plan",
    icon: "person",
  },
  {
    id: 3,
    name: "",
    component: <MeetingAgenda />,
    exact: "exact",
    path: "/meeting-agenda",
    icon: "person",
  },
];

const AuthRoutes = [
  {
    id: 1,
    name: "Login",
    component: <Login />,
    exact: "exact",
    path: "login",
    icon: "person",
  },
];

export { SuperAdminRoutes, AuditorRoutes, AuthRoutes };
