import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import { AuthRoutes, SuperAdminRoutes, AuditorRoutes } from "./routes";
import {
  ProtectedLayout,
  AuthProtectedLayout,
} from "./components/protectedLayout";
import { constants } from "./constants/variables";
import SnackbarAlert from "./components/snackbar/index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setUserData } from "./redux/slices/Authentication";
import CompletionForm from "./containers/app/auditorScreens/completionForm";
import { setDrawerOpen } from "./redux/slices/DrawerToggle";

function App() {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.Authentication);

  useEffect(() => {
    const toggleItem = JSON.parse(localStorage.getItem("toggle_drawer"));
    if (toggleItem) dispatch(setDrawerOpen(toggleItem));
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: ["Inter", "Lato", "sans-serif"].join(","),
    },
    button: {
      fontFamily: ["Inter", "Lato", "sans-serif"].join(","),
    },
  });

  useEffect(() => {
    let dataaaaa = JSON.parse(localStorage.getItem("userData"));
    dispatch(setUserData(dataaaaa));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<AuthProtectedLayout />}>
          {AuthRoutes.map(({ component: Component, path, exact, id }) => {
            return (
              <Route key={id} path={path} exact={exact} element={Component} />
            );
          })}
        </Route>
        <Route path="/" element={<ProtectedLayout />}>
          {userData?.role_name == "admin"
            ? SuperAdminRoutes.map(
                ({ component: Component, path, exact, id }) => {
                  return (
                    <Route
                      key={id}
                      path={path}
                      exact={exact}
                      element={Component}
                    />
                  );
                }
              )
            : AuditorRoutes.map(({ component: Component, path, exact, id }) => {
                return (
                  <Route
                    key={id}
                    path={path}
                    exact={exact}
                    element={Component}
                  />
                );
              })}
        </Route>
        <Route path={"/completion-form"} element={<CompletionForm />} />
      </Routes>
      <SnackbarAlert
        ref={(ref) => {
          constants.snackRef = ref;
        }}
      />
    </ThemeProvider>
  );
}

export default App;
