import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInputLabel from "../../../../components/customInputLabel";
import TextInput from "../../../../components/textInput";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";

function AuditorProfile() {
  const { userData } = useSelector((state) => state.Authentication);
  const navigate = useNavigate()

  return (
    <NavigationDrawer>
      {/* <Loader isloading={isLoading} /> */}
      <Typography variant={"h5"} fontSize={30} fontWeight={700} mb={2}>
        My Profile
      </Typography>

      <Grid container spacing={2} alignItems={"flex-end"} mb={2}>
        <Grid item xs={12} sm={6}>
          <CustomInputLabel label={"Your Name"} />
          <TextInput
            placeholder={"Your Name"}
            value={`${userData?.first_name} ${userData?.last_name}`}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInputLabel label={"Your Email"} />
          <TextInput
            placeholder={"Your Email"}
            value={userData?.email}
            disabled={true}
          />
        </Grid> 
      </Grid>
    </NavigationDrawer>
  );
}

export default AuditorProfile;
