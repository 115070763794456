import { endPoints, requestType } from "../../constants/variables";
import Api from "../index";

export const getNextSteps = (params, token) => {
  return Api(endPoints.fetchNextSteps, params, requestType.POST, token);
};

export const createNextSteps = (params, token) => {
  return Api(endPoints.addNextSteps, params, requestType.POST, token);
};

