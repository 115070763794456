import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData1 = [
  {
    id: 1,
    description:
      "Send in a corrective action plan within 30 days FF125 to be completed by Lead Auditor for client handover. Client is to complete the corrective action and send to info@ffcert.com",
    value: "Yes",
  },
  {
    id: 2,
    description:
      "Grant certification subject to action plan received with evidence",
    value: "Yes",
  },
  {
    id: 3,
    description: "Grant certification",
    value: "N/A",
  },
  {
    id: 4,
    description: "Grant continued certification",
    value: "N/A",
  },
  {
    id: 5,
    description: "Refusal of continuation of certificate (as applicable)",
    value: "N/A",
  },
  {
    id: 5,
    description: "Withdrawal of certificate",
    value: "N/A",
  },
  {
    id: 6,
    description: "Lead Auditor Comments: (as applicable)",
    value: "N/A",
  },
];
const tableData2 = [
  {
    id: 1,
    changes: "Scope change / increase or reduction",
    action: "N",
    details: "",
  },
  {
    id: 2,
    changes: "Change to number of employees:",
    action: "N",
    details: "",
  },
  {
    id: 3,
    changes: "Contact details change:",
    action: "N",
    details: "",
  },
  {
    id: 4,
    changes: "Contact address change:",
    action: "N",
    details: "",
  },
  {
    id: 5,
    changes: "Business name change:",
    action: "N",
    details: "",
  },
  {
    id: 6,
    changes: "Major NC actions:",
    action: "N",
    details: "",
  },
  {
    id: 7,
    changes: "Special visit requirements:",
    action: "N",
    details: "",
  },
  {
    id: 8,
    changes: "Other significant changes (new location being added)",
    action: "N",
    details: "",
  },
  {
    id: 9,
    changes: "Lead Auditor Comments: (as applicable)",
    action: "N/A",
  },
];

const PageEleven = () => {
  const renderSameField = (fieldd, valueeee, indexxx, arrLength) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: arrLength - 1 !== indexxx ? "1px solid black" : "",
        }}
      >
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            paddingLeft: "5px",
            width: arrLength - 1 !== indexxx ? "78%" : "20%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "600",
            paddingTop: "2px",
            paddingLeft: arrLength - 1 !== indexxx ? "0" : "10px",
            flex: 1,
            textAlign: arrLength - 1 !== indexxx ? "center" : "left",
          }}
        >
          {valueeee}
        </Text>
      </View>
    );
  };
  const renderSecondSameField = (
    fieldd,
    valueeee,
    details,
    indexxx,
    arrLength
  ) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: arrLength - 1 !== indexxx ? "1px solid black" : "",
        }}
      >
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            paddingLeft: "5px",
            width: arrLength - 1 !== indexxx ? "68%" : "20%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "600",
            borderRight: details || details == "" ? "1px solid black" : "none",
            paddingTop: "2px",
            paddingLeft: arrLength - 1 !== indexxx ? "0" : "10px",
            flex: 1,
            textAlign: arrLength - 1 !== indexxx ? "center" : "left",
          }}
        >
          {valueeee}
        </Text>
        {(details || details == "") && (
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "400",
              paddingTop: "2px",
              paddingLeft: arrLength - 1 !== indexxx ? "0" : "10px",
              flex: 1,
              textAlign: arrLength - 1 !== indexxx ? "center" : "left",
            }}
          >
            {details}
          </Text>
        )}
      </View>
    );
  };

  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View style={{ width: "80%" }}>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "10px",
              marginBottom: "4px",
            }}
          >
            Recommendations to Head Office
          </Text>
        </View>

        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "6px",
          }}
        >
          {tableData1.map((itemmm, indexxx) => {
            return renderSameField(
              itemmm.description,
              itemmm.value,
              indexxx,
              tableData1.length
            );
          })}
        </View>
        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "15px",
          }}
        >
          {renderSecondSameField(
            "Changes (May require additional audit time)",
            "Action Y/N",
            "Details",
            "",
            tableData2.length
          )}
          {tableData2.map((itemmm, indexxx) => {
            return renderSecondSameField(
              itemmm.changes,
              itemmm.action,
              itemmm.details,
              indexxx,
              tableData2.length
            );
          })}
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageEleven;
