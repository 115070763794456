import { InputLabel, Select } from "@mui/material";
import React from "react";
import { allColors } from "../../constants/colors";

const CustomDropDown = ({
  children,
  fieldLabel,
  handleChange,
  selectedValue,
  fieldName,
}) => {
  return (
    <>
      {fieldLabel && (
        <InputLabel sx={{ marginBottom: "5px" }}>{fieldLabel}</InputLabel>
      )}
      <Select
        sx={{
          background: "linear-gradient(to top, #ed5a37, #f08d24)",
          backgroundColor: allColors.orange,
          borderRadius: "6px",
          color: "#fff",
          fontSize: "13px",
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: allColors.orange,
          },
        }}
        variant="outlined"
        size="small"
        fullWidth
        displayEmpty
        value={selectedValue}
        defaultValue={selectedValue}
        onChange={(e) => handleChange(e, fieldName)}
        MenuProps={{
          sx: {
            "& .MuiPaper-root ul": {
              paddingTop: "0",
              paddingBottom: "0",
            },
            "& .MuiPaper-root ul li": {
              fontSize: "13px",
              borderBottom: "1px solid #00000029",
              paddingTop: "10px",
              paddingBottom: "10px",
            },
          },
        }}
      >
        {children}
      </Select>
    </>
  );
};

export default CustomDropDown;
