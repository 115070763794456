import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData = [
  {
    id: 1,
    name: "Danish Iftikhar",
    position: "Managing Director",
    openMeeting: "Y",
    closeMeeting: "Y",
  },
  {
    id: 2,
    name: "Naeem Ulfat",
    position: "HSQE Consultant",
    openMeeting: "Y",
    closeMeeting: "Y",
  },
  {
    id: 3,
    name: "Mehtab Qureshi",
    position: "Operations Director",
    openMeeting: "Y",
    closeMeeting: "Y",
  },
  {
    id: 4,
    name: "Harris Imbad",
    position: "Security Operative",
    openMeeting: "N",
    closeMeeting: "Y",
  },
  {
    id: 5,
    name: "Bal Gill",
    position: "Auditor",
    openMeeting: "Y",
    closeMeeting: "Y",
  },
  {
    id: 6,
    name: "Amarjit Gill",
    position: "Lead Auditor",
    openMeeting: "Y",
    closeMeeting: "Y",
  },
];

const PageFour = ({ participants, ohsParticipants }) => {
  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View style={{ width: "80%" }}>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Opening and Closing Meeting Participants
          </Text>
          <View
            style={{
              border: "1px solid black",
              alignSelf: "center",
              marginTop: "6px",
              width: "100%",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "700",
                  backgroundColor: "#e7e6e6",
                  flex: 1,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  paddingTop: "2px",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "700",
                  backgroundColor: "#e7e6e6",
                  flex: 1,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  paddingTop: "2px",
                }}
              >
                Position
              </Text>
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "700",
                  backgroundColor: "#e7e6e6",
                  flex: 1,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  paddingTop: "2px",
                }}
              >
                Opening Meeting {"\n"} (Y/N)
              </Text>
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "700",
                  backgroundColor: "#e7e6e6",
                  flex: 1,
                  textAlign: "center",
                  paddingTop: "2px",
                }}
              >
                Closing Meeting {"\n"} (Y/N)
              </Text>
            </View>
            {participants.map((itemmm, indexxxx) => {
              return (
                <View
                  key={itemmm.id}
                  style={{
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      padding: 4,
                      borderRight: "1px solid black",
                      flex: 1,
                    }}
                  >
                    <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                      {itemmm.participant_name}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 4,
                      borderRight: "1px solid black",
                      flex: 1,
                    }}
                  >
                    <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                      {itemmm.participant_position}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 4,
                      borderRight: "1px solid black",
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {itemmm.opening_meeting}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 4,
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "11px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {itemmm.closing_meeting}
                    </Text>
                  </View>
                </View>
              );
            })}
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "700",
                  padding: 4,
                  marginLeft: "4px",
                }}
              >
                OH&S only: Closing meeting attendance invitation to include:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px" }}>
                  The management with legal responsibility for OH&S
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px" }}>
                  Personnel responsible for monitoring employees' health
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px" }}>
                  Employee representatives(s) with responsibility for OH&S
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "800", padding: 4 }}>
                OH&S only: Justification comments in case of absence:
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "800",
              marginVertical: "10px",
            }}
          >
            OH&S Interviewed Participants{" "}
            <Text style={{ fontSize: "10px" }}>
              (applicable to ISO 45001:2018)
            </Text>
          </Text>

          <View
            style={{
              border: "1px solid black",
              alignSelf: "center",
              width: "100%",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                borderBottom: "1px solid black",
              }}
            >
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "700",
                  backgroundColor: "#e7e6e6",
                  flex: 1,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  paddingTop: "2px",
                }}
              >
                Name
              </Text>
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "700",
                  backgroundColor: "#e7e6e6",
                  flex: 1,
                  textAlign: "center",
                  borderRight: "1px solid black",
                  paddingTop: "2px",
                }}
              >
                Position
              </Text>
            </View>
            {ohsParticipants.map((itemmm, indexxxx) => {
              return (
                <View
                  key={itemmm.id}
                  style={{
                    flexDirection: "row",
                    borderBottom: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      padding: 4,
                      borderRight: "1px solid black",
                      flex: 1,
                    }}
                  >
                    <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                      {itemmm.participant_name}
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 4,
                      borderRight: "1px solid black",
                      flex: 1,
                    }}
                  >
                    <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                      {itemmm.participant_position}
                    </Text>
                  </View>
                </View>
              );
            })}
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "700",
                  padding: 4,
                  marginLeft: "4px",
                }}
              >
                Audit team interviews{" "}
                <Text style={{ fontWeight: "800" }}>
                  (over the audit programme)
                </Text>{" "}
                to include:
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px", flex: 1 }}>
                  The management with legal responsibility for OH&S ☒
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px", flex: 1 }}>
                  Employee representatives(s) with responsibility for OH&S ☒
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px", flex: 1 }}>
                  Personnel responsible for monitoring employees' health, for
                  example, doctors and nurses. (Justifications in case of
                  interviews conducted remotely shall be recorded). ☒
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px", flex: 1 }}>
                  Managers and permanent and temporary employees ☒
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px", flex: 1 }}>
                  Managers and employees performing activities related to the
                  prevention of OH&S Safety risks ☒
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ marginHorizontal: 8 }}>•</Text>
                <Text style={{ fontSize: "9px", flex: 1 }}>
                  Contractors' management and employees. ☐ No contractors
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                borderTop: "1px solid black",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "800", padding: 4 }}>
                OH&S only: Justification in case of interviews conducted
                remotely: No Contractors used
              </Text>
            </View>
          </View>
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageFour;
