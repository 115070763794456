import { createSlice } from "@reduxjs/toolkit";

export const DrawerToggle = createSlice({
  name: "DrawerToggle",
  initialState: {
    openDrawer: true,
  },
  reducers: {
    setDrawerOpen: (state, action) => {
      state.openDrawer = action.payload;
    },
  },
});

export const { setDrawerOpen } = DrawerToggle.actions;

export default DrawerToggle.reducer;
