import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";
import HtmlParser from "../../../../components/htmlParser/htmlParser";

const PageSix = ({
  ofiSummary,
  minorSummary,
  majorSummary,
  complaintSummary,
}) => (
  <Page
    size={"A4"}
    wrap
    style={{
      paddingBottom: 65,
    }}
  >
    <PDFHeaderFooter>
      <View style={{ width: "80%" }}>
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "10px",
            marginBottom: "4px",
          }}
        >
          Summary of Opportunities for Improvement (this audit):
        </Text>
      </View>

      <View
        style={{
          border: "1px solid black",
          width: "80%",
          alignSelf: "center",
          marginTop: "6px",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid black",
          }}
        >
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            OFI Nb.
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Clause
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "20%",
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Standard(s)
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "55%",
              textAlign: "center",
              paddingTop: "2px",
            }}
          >
            Description
          </Text>
        </View>
        {ofiSummary.map((itemmm, indexxxx) => {
          return (
            <View
              key={itemmm.id}
              style={{
                flexDirection: "row",
                borderBottom:
                  ofiSummary.length - 1 !== indexxxx
                    ? "1px solid black"
                    : "none",
              }}
            >
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "900" }}>
                  {indexxxx + 1}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  {itemmm.sub_clause_number}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  width: "20%",
                  alignItems: "center",
                }}
              >
                {/* {itemmm.standards.map((valueee) => {
                  return ( */}
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "700",
                    marginVertical: "1px",
                  }}
                >
                  {itemmm.iso_standard_name}
                </Text>
                {/* );
                })} */}
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  width: "55%",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  <HtmlParser html={itemmm.comments} />
                </Text>
              </View>
            </View>
          );
        })}
      </View>

      {/* section 2  */}

      <View style={{ width: "80%" }}>
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "10px",
            marginBottom: "4px",
          }}
        >
          Minor Non-Conformities identified (this audit):
        </Text>
      </View>

      <View
        style={{
          border: "1px solid black",
          width: "80%",
          alignSelf: "center",
          marginTop: "6px",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid black",
          }}
        >
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Minor Nb.
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Clause
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "20%",
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Standard(s)
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "55%",
              textAlign: "center",
              paddingTop: "2px",
            }}
          >
            Description
          </Text>
        </View>

        {minorSummary.map((itemmm, indexxxx) => {
          return (
            <View
              key={itemmm.id}
              style={{
                flexDirection: "row",
                borderBottom: 1,
              }}
            >
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "900" }}>
                  {indexxxx + 1}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  {itemmm.sub_clause_number}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  width: "20%",
                  alignItems: "center",
                }}
              >
                {/* {itemmm.standards.map((valueee) => {
                  return ( */}
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "700",
                    marginVertical: "1px",
                  }}
                >
                  {itemmm.iso_standard_name}
                </Text>
                {/* );
                })} */}
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  width: "55%",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  <HtmlParser html={itemmm.comments} />
                </Text>
              </View>
            </View>
          );
        })}
      </View>

      {/* section 3  */}

      <View style={{ width: "80%" }}>
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "10px",
            marginBottom: "4px",
          }}
        >
          Major Non-Conformities identified (this audit):
        </Text>
      </View>

      <View
        style={{
          border: "1px solid black",
          width: "80%",
          alignSelf: "center",
          marginTop: "6px",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid black",
          }}
        >
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Major Nb.
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Clause
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "20%",
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Standard(s)
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "55%",
              textAlign: "center",
              paddingTop: "2px",
            }}
          >
            Description
          </Text>
        </View>

        {majorSummary.map((itemmm, indexxxx) => {
          return (
            <View
              key={itemmm.id}
              style={{
                flexDirection: "row",
                borderBottom: 1,
              }}
            >
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "900" }}>
                  {indexxxx + 1}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  {itemmm.sub_clause_number}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  width: "20%",
                  alignItems: "center",
                }}
              >
                {/* {itemmm.standards.map((valueee) => {
                  return ( */}
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "700",
                    marginVertical: "1px",
                  }}
                >
                  {itemmm.iso_standard_name}
                </Text>
                {/* );
                })} */}
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  width: "55%",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  <HtmlParser html={itemmm.comments} />
                </Text>
              </View>
            </View>
          );
        })}
      </View>

      {/* section 4 */}

      <View style={{ width: "80%" }}>
        <Text
          style={{
            fontSize: "14px",
            fontWeight: "700",
            marginTop: "10px",
            marginBottom: "4px",
          }}
        >
          Complaint (this audit):
        </Text>
      </View>

      <View
        style={{
          border: "1px solid black",
          width: "80%",
          alignSelf: "center",
          marginTop: "6px",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid black",
          }}
        >
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Complain Nb.
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              flex: 1,
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Clause
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "20%",
              textAlign: "center",
              borderRight: "1px solid black",
              paddingTop: "2px",
            }}
          >
            Standard(s)
          </Text>
          <Text
            style={{
              fontSize: "11px",
              fontWeight: "700",
              width: "55%",
              textAlign: "center",
              paddingTop: "2px",
            }}
          >
            Description
          </Text>
        </View>

        {complaintSummary.map((itemmm, indexxxx) => {
          return (
            <View
              key={itemmm.id}
              style={{
                flexDirection: "row",
                borderBottom: 1,
              }}
            >
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "900" }}>
                  {indexxxx + 1}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  {itemmm.sub_clause_number}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  borderRight: "1px solid black",
                  width: "20%",
                  alignItems: "center",
                }}
              >
                {/* {itemmm.standards.map((valueee) => {
                  return ( */}
                <Text
                  style={{
                    fontSize: "10px",
                    fontWeight: "700",
                    marginVertical: "1px",
                  }}
                >
                  {itemmm.iso_standard_name}
                </Text>
                {/* );
                })} */}
              </View>
              <View
                style={{
                  paddingHorizontal: 2,
                  paddingVertical: 1,
                  width: "55%",
                }}
              >
                <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                  <HtmlParser html={itemmm.comments} />
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </PDFHeaderFooter>
  </Page>
);

export default PageSix;
