import { Document, Font } from "@react-pdf/renderer";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import ClibriRegular from "./fonts/calibri-regular.ttf";
import ClibriBold from "./fonts/calibri-bold.ttf";
import PageThree from "./PageThree";
import PageFour from "./PageFour";
import PageFive from "./PageFive";
import PageSix from "./PageSix";
import PageSeven from "./PageSeven";
import PageNine from "./PageNine";
import PageTen from "./PageTen";
import PageEleven from "./PageEleven";
import PageTwelve from "./PageTwelve";
import PageThirteen from "./PageThirteen";
import PageFourteen from "./PageFourteen";
import PageFifteen from "./PageFifteen";
import PageSixteen from "./PageSixteen";
import PageSeventeen from "./PageSeventeen";
import PageEighteen from "./PageEighteen";
import PageNineteen from "./PageNineteen";
import PageTwenty from "./PageTwenty";
import PageTwentyOne from "./PageTwentyOne";
import PageTwentyTwo from "./PageTwentyTwo";
import PageTwentyThree from "./PageTwentyThree";
import PageTwentyFour from "./PageTwentyFour";
import PageTwentyFive from "./PageTwentyFive";
import PageTwentySix from "./PageTwentySix";
import PageTwentySeven from "./PageTwentySeven";
import PageTwentyEight from "./PageTwentyEight";
import PageTwentyNine from "./PageTwentyNine";
import PageThirty from "./PageThirty";
import PagethirtyOne from "./PageThirtyOne";
import PagethirtyTwo from "./PageThirtyTwo";
import PagethirtyThree from "./PageThirtyThree";
import PagethirtyFour from "./PageThirtyFour";
import PagethirtyFive from "./PageThirtyFive";
import EvidancePage from "./Evidance";

Font.register({
  family: "Calibri",
  fonts: [
    {
      src: ClibriRegular,
      fontStyle: "normal",
      fontWeight: 400,
    },
    {
      src: ClibriBold,
      fontStyle: "normal",
      fontWeight: 700,
    },
  ],
});

const PdfDocument = ({ data }) => {
  return (
    <Document>
      <PageOne
        companyDetails={data.companyDetails[0]}
        isoStandards={data.isoStandards}
      />
      <PageTwo
        reportDetails={
          data?.auditReportDetails[0] ? data.auditReportDetails[0] : []
        }
      />
      <PageThree
        reportDetails={
          data?.auditReportDetails[0] ? data.auditReportDetails[0] : []
        }
      />
      <PageFour
        participants={
          data?.auditReportParticipants ? data.auditReportParticipants : []
        }
        ohsParticipants={
          data?.auditReportOHSParticipants
            ? data.auditReportOHSParticipants
            : []
        }
      />
      <PageFive />
      <PageSix
        ofiSummary={data.ofiSummary}
        minorSummary={data.minorNCSummary}
        majorSummary={data.majorNCSummary}
        complaintSummary={data.compalintSummary}
      />
      {data.majorNCSummary?.map((itemmmmm, inddddd) => {
        return (
          <PageSeven
            titleeee={"Major NC Detail"}
            summaryItem={itemmmmm}
            valIndex={inddddd + 1}
          />
        );
      })}
      {data.minorNCSummary?.map((itemmmmm, inddddd) => {
        return (
          <PageSeven
            titleeee={"Minor NC Detail"}
            summaryItem={itemmmmm}
            valIndex={inddddd + 1}
          />
        );
      })}
      <PageNine
        nextPlan={
          data?.auditReportNextAudit[0] ? data?.auditReportNextAudit[0] : []
        }
      />
      {data?.auditNotes && <PageTen auditNotes={data.auditNotes} />}
      <PageEleven />
      {/* {data?.auditClauseEvidence.map((item) => {
        return <PageTwelve item={item} key={item.id} />;
      })} */}

      {data?.auditClauseEvidence.map((item) => {
        return (
          <EvidancePage item={item} arrayLength={data?.auditClauseEvidence} />
        );
      })}

      {/* <PageTwelve />
      <PageThirteen />
      <PageFourteen />
      <PageFifteen />
      <PageSixteen />
      <PageSeventeen />
      <PageEighteen />
      <PageNineteen /> */}
      {/* <PageTwenty />
  
      <PageTwentyTwo />
      <PageTwentyThree />
      <PageTwentyFour />
      <PageTwentyFive />
      <PageTwentySix />
      <PageTwentySeven />
      <PageTwentyEight />
      <PageTwentyNine />
      <PageThirty /> */}

      <PagethirtyOne data={data.auditNextYearsPlan} />
      {data.iso_nc_ofi &&
        Object.entries(data.iso_nc_ofi).map(([standard, subCluases], index) => {
          return (
            <PagethirtyTwo
              standard={standard}
              subCluases={subCluases}
              index={index}
            />
          );
        })}
      {/* <PagethirtyThree />
      <PagethirtyFour /> */}
      <PagethirtyFive />
    </Document>
  );
};

export default PdfDocument;
