import { Box, DialogContent, Grid, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import { addCompany, updateCompany } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import { addAuditor, updateAuditor } from "../../../../services/auditors";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AddEditAuditor({
  type,
  open,
  handleClose,
  dialogData,
  fetchData,
  onclosePress,
  setIsLoading,
}) {
  const [inputVal, setInputVal] = useState({ firstname: "", lastname: "", email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (type === "edit" && dialogData) {
      setInputVal({
        firstname: dialogData.first_name,
        lastname: dialogData.last_name,
        email: dialogData.email,
      });
    } else {
      setInputVal({ firstname: "", lastname: "", email: "", password: "" });
    }
  }, [type, dialogData]);

  const handleChange = (e, name) => {
    setInputVal((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    const { firstname, email, lastname, password } = inputVal
    let params = {
      first_name: firstname,
      last_name: lastname,
      email
    };

    if (type !== "edit") {
      params.password = password;
    }
    setIsLoading(true);
    try {
      if (type === "add") {
        let result = await addAuditor(params);
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Auditor added successfully",
          });
          fetchData();
          handleClose();
          setInputVal({ firstname: "", lastname: "", email: "", password: "" });
        }
        else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
      } else {
        let result = await updateAuditor({ id: dialogData.id, ...params });
        if (result.status == 200) {
          snackRef.current.isOpenSnack({
            snackType: "success",
            snackMessage: "Auditor updated successfully",
          });
          setInputVal({ firstname: "", lastname: "", email: "", password: "" });
          fetchData();
          handleClose();
        }
        else {
          snackRef.current.isOpenSnack({
            snackType: "error",
            snackMessage: result.data.message,
          });
        }
      }
    } catch (err) {
      console.error("Error saving company:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const snackRef = useRef();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog onclosePress={onclosePress} onClose={onclosePress} open={open}>
        <DialogHeader
          title={type === "add" ? "Add Auditor" : "Edit Auditor"}
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <CustomInputLabel label="First Name" />
              <TextInput
                placeholder="First Name"
                value={inputVal.firstname}
                onChange={(e) => handleChange(e, "firstname")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Last Name" />
              <TextInput
                placeholder="Last Name"
                value={inputVal.lastname}
                onChange={(e) => handleChange(e, "lastname")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Email" />
              <TextInput
                placeholder="Enter email here"
                value={inputVal.email}
                onChange={(e) => handleChange(e, "email")}
              />
            </Grid>
            {type != "edit" &&
              <Grid item xs={12}>
                <CustomInputLabel label="Password" />
                <TextInput
                  type={showPassword ? "type" : "password"}
                  placeholder="Enter your password"
                  value={inputVal.password}
                  onChange={(e) => handleChange(e, "password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={handleSave}
          handleUpdate={handleSave}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default AddEditAuditor;
