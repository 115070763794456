import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import SnackbarAlert from "../../../../components/snackbar";
import AddEditSubClause from "./AddEditSubClauses";
import { getAllSubClauses } from "../../../../services/subClauses";
import DescriptionDialog from "./DescriptionDialog";
import { getAllClauses } from "../../../../services/clauses";

const headerData = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  { id: "title", numeric: false, disablePadding: true, label: "Title" },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "subClauseNumber",
    numeric: false,
    disablePadding: true,
    label: "Sub Clause Number",
  },
  {
    id: "clauseNumber",
    numeric: false,
    disablePadding: true,
    label: "Clause Number",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    align: "center",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    align: "center",
  },
];

const displayRows = [
  "id",
  "title",
  "show_view_btn",
  "sub_clause_number",
  "clause_number",
  "showStatus",
  "actions",
];

function SubClauses() {
  const [subClauses, setSubClauses] = useState([]);
  const [clauses, setClauses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const snackRef = useRef();
  const openDesDialog = useRef(null);

  useEffect(() => {
    fetchAllSubClauses();
    fetchAllClauses();
  }, []);

  const fetchAllSubClauses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllSubClauses();
      setSubClauses(response.data.data.subClauses);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllClauses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllClauses();
      setClauses(response.data.data.clauses);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    fetchAllSubClauses();
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const handleChangeStatus = (status, row) => {};

  return (
    <>
      <SnackbarAlert ref={snackRef} />
      <NavigationDrawer>
        <Loader isloading={isLoading} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
          mt={"5em"}
          mb={4}
        >
          <Typography variant="h5" fontSize={30} fontWeight={700}>
            Sub Clauses
          </Typography>
          <Box height={40}>
            <CustomButton
              btnLabel="Add Sub Clause"
              handlePressBtn={() => handleOpenDialog("add")}
            />
          </Box>
        </Box>

        <DynamicTable
          headerData={headerData}
          bodyRows={subClauses}
          displayData={displayRows}
          showEdit
          handleChangeStatus={(status, data) =>
            handleChangeStatus(status, data)
          }
          showDelete
          openEditDialog={(row) => handleOpenDialog("edit", row)}
          handlePressView={(row) =>
            openDesDialog.current.isVisible({ data: row })
          }
        />

        <AddEditSubClause
          type={type}
          onclosePress={handleCloseDialog}
          fetchData={fetchAllSubClauses}
          open={openDialog}
          handleClose={handleCloseDialog}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
          clauses={clauses}
        />
        <DescriptionDialog ref={openDesDialog} />
      </NavigationDrawer>
    </>
  );
}

export default SubClauses;
