import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import SnackbarAlert from "../../../../components/snackbar";
import { getAllSubClauses } from "../../../../services/subClauses";
import AddEditLinktoSubClause from "./AddEditLinkISOtoSubClause";
import { getAllIsoSubClause } from "../../../../services/linkIsotoSubClause";
import { getAllStandards } from "../../../../services/isoStandards";
import DescriptionDialog from "./DescriptionDialog";

const headerData = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "ISO Standard Name",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Sub Clause Title",
  },
  {
    id: "subClauseNumber",
    numeric: false,
    disablePadding: true,
    label: "Sub Clause Number",
  },
];

const displayRows = [
  "iso_standard_name",
  "sub_clause_title",
  "sub_clause_number",
];

function LinkISOToSubClause() {
  const [linkedClauses, setLinkedCluases] = useState([]);
  const [isoStandards, setIsoStandards] = useState([]);
  const [allClauses, setAllClauses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const openDesDialog = useRef(null);
  const snackRef = useRef();

  useEffect(() => {
    fetchAllSubClauses();
    fetchAllStandards();
    fetchAllClauses();
  }, []);

  const fetchAllStandards = async () => {
    setIsLoading(true);
    try {
      const response = await getAllStandards();
      setIsoStandards(response.data.data.isoStandards);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchAllSubClauses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllIsoSubClause();
      let result = response.data.data.isoSubClause.sort((a, b) => {
        const keyA = a.sub_clause_number.toLowerCase();
        const keyB = b.sub_clause_number.toLowerCase();
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
      setLinkedCluases(result);
    } catch (err) {
      console.error("Error fetching all isos :", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllClauses = async () => {
    setIsLoading(true);
    try {
      const response = await getAllSubClauses();
      setAllClauses(response.data.data.subClauses);
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    fetchAllSubClauses();
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const handleOpenDesDialog = (row) => {
    openDesDialog.current.isVisible({ data: row });
  };

  const handleChangeStatus = (status, row) => {};

  return (
    <>
      <SnackbarAlert ref={snackRef} />
      <NavigationDrawer>
        <Loader isloading={isLoading} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
          mt={"5em"}
          mb={4}
        >
          <Typography variant="h5" fontSize={30} fontWeight={700}>
            Link ISO - Sub Clause
          </Typography>
          <Box height={40}>
            <CustomButton
              btnLabel="Create New Link"
              handlePressBtn={() => handleOpenDialog("add")}
            />
          </Box>
        </Box>

        <DynamicTable
          headerData={headerData}
          bodyRows={linkedClauses}
          displayData={displayRows}
          showEdit
          handleChangeStatus={(status, data) =>
            handleChangeStatus(status, data)
          }
          showDelete
          openEditDialog={(row) => handleOpenDialog("edit", row)}
          handlePressView={handleOpenDesDialog}
        />

        <AddEditLinktoSubClause
          type={type}
          onclosePress={handleCloseDialog}
          fetchData={fetchAllStandards}
          open={openDialog}
          isoStandards={isoStandards}
          handleClose={handleCloseDialog}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
          allClauses={allClauses}
        />
        <DescriptionDialog ref={openDesDialog} />
      </NavigationDrawer>
    </>
  );
}

export default LinkISOToSubClause;
