import { Box, DialogContent, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "../../../../components/dialog/dialogFooter";
import DialogHeader from "../../../../components/dialog/dialogHeader";
import BootstrapDialog from "../../../../components/dialog/index";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import CustomDropDown from "../../../../components/customDropDown";
import {
  createParticipants,
  updateParticipants,
} from "../../../../services/participants";
import { useSelector } from "react-redux";

function AddEditAuditPlan({
  type,
  open,
  onAddNewData,
  handleClose,
  dialogData,
  fetchData,
  onclosePress,
  setIsLoading,
  companyId,
  updateTableData,
}) {
  const { userData } = useSelector((state) => state.Authentication);
  const [inputVal, setInputVal] = useState({
    day: "",
    time: "",
    auditor: "",
    process: "",
  });

  useEffect(() => {
    if (type === "edit" && dialogData) {
      setInputVal({
        day: dialogData?.day,
        time: dialogData?.time,
        auditor: dialogData?.auditor,
        process: dialogData?.process,
      });
    } else {
      setInputVal({
        day: "",
        time: "",
        auditor: "",
        process: "",
      });
    }
  }, [type, dialogData]);

  const handleChange = (e, name) => {
    setInputVal((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleSave = async (dat) => {
    if (inputVal.day == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter Day",
      });
    } else if (inputVal.time == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please enter time",
      });
    } else if (inputVal.auditor == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please select auditor name",
      });
    } else if (inputVal.process == "") {
      snackRef.current.isOpenSnack({
        snackType: "error",
        snackMessage: "Please write process",
      });
    } else {
      onAddNewData(inputVal);
      handleClose();
    }
    //   setIsLoading(true);
    //   let params = {
    //     ...(type === "add" && { company_audit_id: companyId }),
    //     participant_name: inputVal.name,
    //     participant_position: inputVal.position,
    //     opening_meeting: inputVal.openMeeting == "Yes" ? "Y" : "N",
    //     closing_meeting: inputVal.closeMeeting == "Yes" ? "Y" : "N",
    //   };
    //   try {
    //     if (type === "add") {
    //       await createParticipants(params, userData.token);
    //       fetchData();
    //       snackRef.current.isOpenSnack({
    //         snackType: "success",
    //         snackMessage: "Participant added successfully",
    //       });
    //     } else {
    //       await updateParticipants(
    //         { ...params, id: dialogData.id },
    //         userData.token
    //       );
    //       fetchData();
    //       snackRef.current.isOpenSnack({
    //         snackType: "success",
    //         snackMessage: "Participant updated successfully",
    //       });
    //     }
    //   } catch (err) {
    //     console.error("Error saving company:", err);
    //   } finally {
    //     setIsLoading(false);
    //     handleClose();
    //   }
    // }
  };

  const snackRef = useRef();

  return (
    <Box>
      <SnackbarAlert ref={snackRef} />
      <BootstrapDialog
        onclosePress={onclosePress}
        onClose={onclosePress}
        open={open}
      >
        <DialogHeader
          title={type === "add" ? "Add Audit Details" : "Edit Audit Details"}
          handleClose={onclosePress}
        />
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <CustomInputLabel label="Day" />
              <TextInput
                placeholder="1"
                value={inputVal.day}
                onChange={(e) => handleChange(e, "day")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Time" />
              <TextInput
                placeholder="9:00"
                value={inputVal.time}
                onChange={(e) => handleChange(e, "time")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Auditor" />
              <TextInput
                placeholder="AG"
                value={inputVal.auditor}
                onChange={(e) => handleChange(e, "auditor")}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputLabel label="Process" />
              <TextInput
                placeholder="process"
                value={inputVal.process}
                onChange={(e) => handleChange(e, "process")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogFooter
          type={type}
          handleCreate={() => {
            if (type != "edit") {
              handleClose();
              handleSave(inputVal);
            } else {
              updateTableData(inputVal);
            }
          }}
          handleUpdate={() => {
            handleClose();
            updateTableData(inputVal);
          }}
          handleClose={handleClose}
        />
      </BootstrapDialog>
    </Box>
  );
}

export default AddEditAuditPlan;
