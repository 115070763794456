import { endPoints, requestType } from '../../constants/variables';
import Api from "../index";

export const getAllStandards = () => {
    return Api(endPoints.iso, {}, requestType.POST);
};

export const addISOStandard = (params) => {
    return Api(endPoints.addISO, params, requestType.POST);
}

export const updateISOStandard = (params) => {
    return Api(endPoints.updateISO, params, requestType.POST);
}
export const updateIsoStandardStatus = (params, id) => {
    return Api(`${endPoints.updateIsoStandardStatus}/${id}`, params, requestType.POST);
}

