import { endPoints, requestType } from '../../constants/variables';
import Api from "../index";

export const getAllCompanies = () => {
    return Api(endPoints.companies, {}, requestType.POST);
};

export const addCompany = (params) => {
    return Api(endPoints.addCompany, params, requestType.POST);
}

export const updateCompany = (params) => {
    return Api(endPoints.updateCompany, params, requestType.POST);
}
export const updateCompanyStatus = (params, id) => {
    return Api(`${endPoints.updateCompanyStatus}/${id}`, params, requestType.POST);
}
