import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import useStyles from "../../globalStyles";

function TextInput({
  placeholder,
  name,
  value,
  onChange,
  type,
  id,
  fullWidth,
  multiline,
  rows,
  disabled,
  InputProps,
}) {
  const classes = useStyles();
  return (
    <TextField
      sx={{
        borderColor: "#f26434",
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#f26434",
          },
        },
      }}
      id={id}
      type={type ? type : "text"}
      variant={"outlined"}
      size={"small"}
      fullWidth={fullWidth ? fullWidth : true}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      multiline={multiline ? multiline : false}
      className={classes.globalInput}
      rows={rows}
      disabled={disabled ? disabled : false}
      InputProps={InputProps}
      // InputProps={{
      //   readOnly: readonly ? readonly : false,
      //   startAdornment: (
      //     <InputAdornment position="start">{InputStartIcon}</InputAdornment>
      //   ),
      //   endAdornment: (
      //     <InputAdornment position="end" sx={{ cursor: "pointer" }}>
      //       {InputEndIcon}
      //     </InputAdornment>
      //   ),
      // }}
    />
  );
}

export default TextInput;
