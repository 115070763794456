import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable from "../../../../components/dynamicTable";
import Loader from "../../../../components/loader";
import NavigationDrawer from "./../../../../components/navigationDrawer/index";
import CustomButton from "../../../../components/customButton";
import { getAllParticipants } from "../../../../services/participants";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { updateAuditStatus } from "../../../../services/auditors";
import SnackbarAlert from "../../../../components/snackbar";
import AddEditOhs from "./AddEditOhsParticipant";
import { getAllOHSParticipants } from "../../../../services/ohsparticipants";

const headerData = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Participant Name",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: true,
    label: "Position",
  },
  { id: "action", numeric: false, disablePadding: true, label: "Actions" },
];

const displayRows = [
  "id",
  "participant_name",
  "participant_position",
  "actions",
];

function OhsParticipants() {
  const { userData } = useSelector((state) => state.Authentication);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("add");
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  let snackRef = useRef();
  useEffect(() => {
    fetchAllParticipants();
  }, []);

  const fetchAllParticipants = async () => {
    setIsLoading(true);
    try {
      const response = await getAllOHSParticipants(
        {
          company_audit_id: state.id,
        },
        userData.token
      );
      if (response.data?.data) {
        setParticipants(response.data.data.getAuditReportOHSParticipants);
      }
    } catch (err) {
      console.error("Error fetching companies:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
  };

  const handleOpenDialog = (type, data = null) => {
    setType(type);
    setDialogData(data);
    setOpenDialog(true);
  };

  const onNextPress = async () => {
    navigate("/start-auditing", { state });
    // let params = {
    //   id: state.companyId,
    //   audit_status: "Completed",
    // };
    // let result = await updateAuditStatus(params);
    // if (result.status == 200) {
    //   snackRef.current.isOpenSnack({
    //     snackType: "success",
    //     snackMessage: "Audit Completed Successfully",
    //   });
    //   setTimeout(() => {
    //     navigate("/");
    //   }, 2000);
    // } else {
    //   snackRef.current.isOpenSnack({
    //     snackType: "error",
    //     snackMessage: result.data.message,
    //   });
    // }
  };

  return (
    <NavigationDrawer>
      <SnackbarAlert ref={snackRef} />
      <Loader isloading={isLoading} />
      <div style={{ paddingBottom: "50px" }}>
        <Box style={{ display: "flex", alignItems: "center" }} mb={3}>
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer", marginRight: "12px" }}
          />
          <Typography variant="h5" fontSize={30} fontWeight={700}>
            OHS Participants
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" gap="10px" mt={2} mb={2}>
          <Box height={40} sx={{ width: {} }}>
            <CustomButton
              btnLabel="Add OHS Participant"
              handlePressBtn={() => handleOpenDialog("add")}
            />
          </Box>
        </Box>

        <DynamicTable
          headerData={headerData}
          bodyRows={participants}
          displayData={displayRows}
          showEdit
          openEditDialog={(row) => handleOpenDialog("edit", row)}
        />

        <div style={{ textAlign: "right", marginTop: "10px", height: "40px" }}>
          <CustomButton btnLabel="Next" handlePressBtn={onNextPress} />
        </div>

        <AddEditOhs
          type={type}
          onclosePress={handleCloseDialog}
          fetchData={fetchAllParticipants}
          open={openDialog}
          handleClose={handleCloseDialog}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
          companyId={state.companyId}
        />

        {/* <div
          style={{
            padding: 10,
            background: "white",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "right",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
          }}
        >
          <Box
            height={40}
            sx={{
              position: "relative",
              zIndex: 99999,
              textAlign: "right",
              margin: "0 0 0 auto",
              width: { xs: "100%" },
            }}
          > */}

        {/* </Box>
        </div> */}
      </div>
    </NavigationDrawer>
  );
}

export default OhsParticipants;
