import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData = [
  {
    day: 1,
    time: "09.00",
    auditor: "AG",
    process: "Opening meeting, changes, office review",
  },
  {
    day: 1,
    time: "09.45",
    auditor: "AG",
    process: "Context, internal and external issues, interested parties",
  },
  {
    day: 1,
    time: "10.30",
    auditor: "AG",
    process: "Scope, processes, interaction of processes",
  },
  {
    day: 1,
    time: "11.00",
    auditor: "AG",
    process: "Risks, opportunities and controls for QMS, EMS and HSMS",
  },
  {
    day: 1,
    time: "12.30",
    auditor: "AG",
    process: "Lunch",
  },
  {
    day: 1,
    time: "13.00",
    auditor: "AG",
    process:
      "Policy, communication, awareness, and inductions, roles and responsibilities",
  },
  {
    day: 1,
    time: "14.00",
    auditor: "AG",
    process: "Training, competence and organisational knowledge",
  },
  {
    day: 1,
    time: "15.00",
    auditor: "AG",
    process: "Objectives, QMS, EMS and HSMS monitor and measuring",
  },
  {
    day: 1,
    time: "16.30",
    auditor: "AG",
    process: "Day 1 Summary",
  },
  {
    day: 1,
    time: "17.00",
    auditor: "AG",
    process: "End of Day",
  },
  {
    day: 2,
    time: "09.00",
    auditor: "AG",
    process: "Morning Review",
  },
  {
    day: 2,
    time: "09.30",
    auditor: "AG",
    process:
      "Office infrastructure, risk assessments, emergency preparedness, environmental and health and safety controls",
  },
  {
    day: 2,
    time: "11.00",
    auditor: "AG",
    process:
      "Environmental aspects and impacts, control of significant aspects and communication",
  },
  {
    day: 2,
    time: "12.30",
    auditor: "AG",
    process: "Lunch",
  },
  {
    day: 2,
    time: "13.00",
    auditor: "AG",
    process: "Legal compliance and evaluation of legal compliance",
  },
  {
    day: 2,
    time: "15.00",
    auditor: "AG",
    process:
      "Operational control – Facilities Management - site file and documentation, internal and external communication, competence, Risk assessment procedures, Accident and incident procedures",
  },
  {
    day: 2,
    time: "16.30",
    auditor: "AG",
    process: "Day 2 Summary",
  },
  {
    day: 2,
    time: "17.00",
    auditor: "AG",
    process: "End of Day",
  },
  {
    day: 3,
    time: "09.00",
    auditor: "AG",
    process: "Morning Review",
  },
  {
    day: 3,
    time: "09.15",
    auditor: "AG",
    process:
      "Site Visit – Operational Control – Facilities Management and Security. RAMS, COSHH, SLA, Infrastructure, External Property, Internal and External Communication, Consultation and Participation",
  },
  {
    day: 3,
    time: "13.00",
    auditor: "AG",
    process: "Lunch",
  },
  {
    day: 3,
    time: "13.30",
    auditor: "AG",
    process: "Purchasing, resource planning",
  },
  {
    day: 3,
    time: "14.00",
    auditor: "AG",
    process:
      "Internal Audit Programme, Internal Audit, Nonconformances, EMS and HSMS incidents, Complaints/Customer Satisfaction, Management Review and Continual Improvement Activities",
  },
  {
    day: 3,
    time: "15.00",
    auditor: "AG",
    process: "Report Preparation",
  },
  {
    day: 3,
    time: "16.30",
    auditor: "AG",
    process: "Closing meeting",
  },
  {
    day: 3,
    time: "17.00",
    auditor: "AG",
    process: "End of Day",
  },
];

const PageNine = ({ nextPlan }) => {
  const {
    next_audit_date,
    next_audit_days,
    next_audit_type,
    next_auditor,
    next_audit_details,
  } = nextPlan;
  const renderSameField = (fieldd, valueeee) => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottom: fieldd == "Audit Type" ? "" : "1px solid black",
        }}
      >
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "900",
            borderRight: "1px solid black",
            paddingTop: "2px",
            paddingLeft: "5px",
            width: "20%",
          }}
        >
          {fieldd}
        </Text>
        <Text
          style={{
            fontSize: "11px",
            fontWeight: "400",
            paddingTop: "2px",
            flex: 1,
            paddingLeft: "5px",
          }}
        >
          {valueeee}
        </Text>
      </View>
    );
  };

  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View style={{ width: "80%" }}>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "10px",
              marginBottom: "4px",
            }}
          >
            Next Audit Plan
          </Text>
        </View>

        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "6px",
          }}
        >
          {renderSameField("Audit Date", next_audit_date)}
          {renderSameField("Auditor", next_auditor)}
          {renderSameField("Audit Days", next_audit_days)}
          {renderSameField("Audit Type", next_audit_type)}
        </View>

        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "12px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Day
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Time
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Auditor
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                width: "55%",
                textAlign: "center",
                paddingTop: "2px",
              }}
            >
              Process
            </Text>
          </View>
          {next_audit_details.map((itemmm, indexxxx) => {
            return (
              <View
                key={itemmm.id}
                style={{
                  flexDirection: "row",
                  borderBottom:
                    next_audit_details.length - 1 !== indexxxx
                      ? "1px solid black"
                      : "none",
                }}
              >
                <View
                  style={{
                    paddingHorizontal: 2,
                    paddingVertical: 1,
                    borderRight: "1px solid black",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontSize: "10px", fontWeight: "900" }}>
                    {itemmm.day}
                  </Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 2,
                    paddingVertical: 1,
                    borderRight: "1px solid black",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                    {itemmm.time}
                  </Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 2,
                    paddingVertical: 1,
                    borderRight: "1px solid black",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                    {itemmm.auditor}
                  </Text>
                </View>
                <View
                  style={{
                    paddingHorizontal: 2,
                    paddingVertical: 1,
                    width: "55%",
                  }}
                >
                  <Text style={{ fontSize: "10px", fontWeight: "700" }}>
                    {itemmm.process}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageNine;
