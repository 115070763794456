export const allColors = {
  blue: "#3978e2",
  orange: "#ee6739",
  black: "#000000",
  red: "red",
  lightGray: "#d3d3d3",
  darkOrange:"#C40C0C",
  orangeGradient:'linear-gradient(to top, #ed5a37, #f08d24)',
};
export const primaryColor = "#3978e2";

export const secondaryColor = "#000000";
