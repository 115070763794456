import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";
import HtmlParser from "../../../../components/htmlParser/htmlParser";

const allHeading = {
  audit_activity_place: "Audit Activity Plan",
  audit_duration: "Audit Duration",
  audit_end_date: "Audti End Date",
  audit_objective: "Audit Objective",
  audit_start_date: "Audit Start Date",
  audit_summary: "Audit Summary",
  audit_team: "Audit Team",
  client_reference: "Client Reference",
  non_applicable_clauses: "Non Applicable Clause",
  number_of_employees: "Number Of Employee",
  scope_of_certification: "Scope Of Certification",
  shift_pattern: "Shift Pattern",
};

const dataaaa = [
  {
    id: 1,
    title: "Site Address:",
    description:
      "1b First Floor, Bank House, The Paddock, Handforth, Wilmslow, SK9 3HQ",
  },
  {
    id: 2,
    title: "Client reference:",
    description: "508",
  },
  {
    id: 3,
    title: "Scope of Certification",
    description: "Facilities management and security guarding services",
  },
  {
    id: 4,
    title: "Non applicable clauses",
    description:
      "The non applicability is stated for 8.3 design and development. The company does not engage in bespoke design and development activities for customers. Rather, the services provided are tailored to meet customer requirements. 7.1.5.2 measurement traceability. There is no calibrated equipment for any critical to quality measurement requirements.",
  },
  {
    id: 5,
    title: "Audit dates:",
    description: "27/03/2024 and 28/03/2024",
  },
  {
    id: 6,
    title: "Audit Duration Days:",
    description: "3 auditor days",
  },
  {
    id: 7,
    title: "Report Author:",
    description: "Amarjit Gill",
  },
  {
    id: 8,
    title: "Audit team:",
    description: "Amarjit Gill",
  },
  {
    id: 9,
    title: "Number of Sites:",
    description: "1",
  },
  {
    id: 10,
    title:
      "State the place of the audit activities (onsite or offsite, permanent or temporary sites included)",
    description: "Site visit: Stella Traders warehouse",
  },
  {
    id: 11,
    title: "Number of Employees:",
    description: "12",
  },
  {
    id: 12,
    title: "Shift patterns worked:",
    description: "9:00-17:00",
  },
];
const tableData = [
  {
    id: 1,
    address: "n/a",
    employees: "",
    confirmEmployees: "",
    scopeForSite: "",
    scopeChanged: "",
    shiftPatterns: "",
  },
  {
    id: 2,
    address: "",
    employees: "",
    confirmEmployees: "",
    scopeForSite: "",
    scopeChanged: "",
    shiftPatterns: "",
  },
  {
    id: 3,
    address: "",
    employees: "",
    confirmEmployees: "",
    scopeForSite: "",
    scopeChanged: "",
    shiftPatterns: "",
  },
  {
    id: 4,
    address: "",
    employees: "",
    confirmEmployees: "",
    scopeForSite: "",
    scopeChanged: "",
    shiftPatterns: "",
  },
  {
    id: 5,
    address: "",
    employees: "",
    confirmEmployees: "",
    scopeForSite: "",
    scopeChanged: "",
    shiftPatterns: "",
  },
  {
    id: 6,
    address: "",
    employees: "",
    confirmEmployees: "",
    scopeForSite: "",
    scopeChanged: "",
    shiftPatterns: "",
  },
];

const getItems = (allVaues) => {
  return {
    client_reference: allVaues.client_reference,
    scope_of_certification: allVaues.scope_of_certification,
    non_applicable_clauses: allVaues.non_applicable_clauses,
  };
};

const PageTwo = ({ reportDetails }) => {
  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "30px",
          }}
        >
          {/* {Object.keys(getItems(reportDetails)).map((itemmm, indexxxx) => {
            return ( */}
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Site Address
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails.site_address}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Client reference:
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.client_reference}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // borderBottom:
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Scope of Certification:
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.scope_of_certification}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Non applicable clauses
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                <HtmlParser html={reportDetails?.non_applicable_clauses} />
                {/* {reportDetails?.non_applicable_clauses} */}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Audit dates:
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.audit_start_date} and{" "}
                {reportDetails?.audit_end_date}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Audit Duration Days:
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.audit_duration}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              // borderBottom:
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Report Author:
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.report_author}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Audit team:
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.audit_team}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Number of Sites
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.number_of_sites}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                State the place of the audit activities (onsite or offsite,
                permanent or temporary sites included)
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.audit_activity_place}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              borderBottom: 1,
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Number of Employees
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.number_of_employees}
              </Text>
            </View>
          </View>
          <View
            // key={itemmm.id}
            style={{
              flexDirection: "row",
              // borderBottom:
              // dataaaa.length - 1 !== indexxxx ? "1px solid black" : "none",
            }}
          >
            {/* <RenderItem /> */}
            <View
              style={{
                paddingHorizontal: 5,
                paddingVertical: 3,
                borderRight: "1px solid black",
                width: "40%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                Shift patterns worked
              </Text>
            </View>
            <View
              style={{
                paddingHorizontal: 5,
                marginVertical: 3,
                width: "60%",
              }}
            >
              <Text style={{ fontSize: "12px", textAlign: "justify" }}>
                {reportDetails?.shift_pattern}
              </Text>
            </View>
          </View>
          {/* );
          })} */}
        </View>
        <View style={{ width: "80%" }}>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "700",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Client other locations (as applicable): Not Applicable
          </Text>
        </View>

        <View
          style={{
            border: "1px solid black",
            width: "80%",
            alignSelf: "center",
            marginTop: "6px",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              borderBottom: "1px solid black",
            }}
          >
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                backgroundColor: "#e7e6e6",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Site Address
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                backgroundColor: "#e7e6e6",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              No. of employees
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                backgroundColor: "#e7e6e6",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Confirm current employees
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                backgroundColor: "#e7e6e6",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Scope for site (s)
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                backgroundColor: "#e7e6e6",
                flex: 1,
                textAlign: "center",
                borderRight: "1px solid black",
                paddingTop: "2px",
              }}
            >
              Has the scope changed?
            </Text>
            <Text
              style={{
                fontSize: "11px",
                fontWeight: "700",
                backgroundColor: "#e7e6e6",
                flex: 1,
                textAlign: "center",
                paddingTop: "2px",
              }}
            >
              Shift Patterns
            </Text>
          </View>
          {tableData.map((itemmm, indexxxx) => {
            return (
              <View
                key={itemmm.id}
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                }}
              >
                <View
                  style={{
                    padding: 7,
                    borderRight: "1px solid black",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                    {itemmm.address}
                  </Text>
                </View>
                <View
                  style={{
                    padding: 7,
                    borderRight: "1px solid black",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                    {itemmm.employees}
                  </Text>
                </View>
                <View
                  style={{
                    padding: 7,
                    borderRight: "1px solid black",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                    {itemmm.confirmEmployees}
                  </Text>
                </View>
                <View
                  style={{
                    padding: 7,
                    borderRight: "1px solid black",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                    {itemmm.scopeForSite}
                  </Text>
                </View>
                <View
                  style={{
                    padding: 7,
                    borderRight: "1px solid black",
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                    {itemmm.scopeChanged}
                  </Text>
                </View>
                <View
                  style={{
                    padding: 7,
                    flex: 1,
                  }}
                >
                  <Text style={{ fontSize: "11px", fontWeight: "700" }}>
                    {itemmm.shiftPatterns}
                  </Text>
                </View>
              </View>
            );
          })}
          <View
            style={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                padding: 5,
                borderRight: "1px solid black",
                width: "16.6%",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "700",
                }}
              >
                Comment on changes
              </Text>
            </View>
            <View
              style={{
                padding: 7,
                width: "80%",
              }}
            >
              <Text style={{ fontSize: "11px", fontWeight: "700" }}></Text>
            </View>
          </View>
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PageTwo;
