import { Box, DialogContent, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextInput from "../../../../components/textInput";
import DialogFooter from "./../../../../components/dialog/dialogFooter";
import DialogHeader from "./../../../../components/dialog/dialogHeader";
import BootstrapDialog from "./../../../../components/dialog/index";
import { addCompany, updateCompany } from "../../../../services/company";
import SnackbarAlert from "../../../../components/snackbar";
import CustomInputLabel from "../../../../components/customInputLabel";
import { addISOStandard, updateISOStandard } from "../../../../services/isoStandards";

function AddEditISOStandard({
    type,
    open,
    handleClose,
    dialogData,
    fetchData,
    onclosePress,
    setIsLoading,
}) {
    const [inputVal, setInputVal] = useState({ name: "", description: "" });

    useEffect(() => {
        if (type === "edit" && dialogData) {
            setInputVal({
                name: dialogData.name,
                description: dialogData.description,
            });
        } else {
            setInputVal({ name: "", description: "" });
        }
    }, [type, dialogData]);

    const handleChange = (e, name) => {
        setInputVal((prev) => ({
            ...prev,
            [name]: e.target.value,
        }));
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            if (type === "add") {
                await addISOStandard(inputVal);
                snackRef.current.isOpenSnack({
                    snackType: "success",
                    snackMessage: "ISO Standard added successfully",
                });
                setInputVal({ name: "", description: "" });
            } else {
                await updateISOStandard({ id: dialogData.id, ...inputVal });
                snackRef.current.isOpenSnack({
                    snackType: "success",
                    snackMessage: "ISO Standard updated successfully",
                });
                setInputVal({ name: "", description: "" });
            }
            fetchData();
        } catch (err) {
            console.error("Error saving company:", err);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const snackRef = useRef();

    return (
        <Box>
            <SnackbarAlert ref={snackRef} />
            <BootstrapDialog onclosePress={onclosePress} onClose={onclosePress} open={open}>
                <DialogHeader
                    title={type === "add" ? "Add ISO Standard" : "Edit ISO Standard"}
                    handleClose={onclosePress}
                />
                <DialogContent dividers>
                    <Grid container spacing={2} alignItems="flex-end">
                        <Grid item xs={12}>
                            <CustomInputLabel label="Standard Name" />
                            <TextInput
                                placeholder="Standard Name"
                                value={inputVal.name}
                                onChange={(e) => handleChange(e, "name")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInputLabel label="Standard Description" />
                            <TextInput
                                multiline
                                rows={4}
                                fullWidth
                                placeholder="Standard Description"
                                value={inputVal.description}
                                onChange={(e) => handleChange(e, "description")}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogFooter
                    type={type}
                    handleCreate={handleSave}
                    handleUpdate={handleSave}
                    handleClose={handleClose}
                />
            </BootstrapDialog>
        </Box>
    );
}

export default AddEditISOStandard;
