import { Page, View, Text } from "@react-pdf/renderer";
import PDFHeaderFooter from "./../../../../components/pdfHeaderFooter/index";

const tableData1 = [
  {
    id: 1,
    serialNo: "4.1",
    name: "Understanding the organisation and its context",
    initialVisit: {
      nci: "",
      ofi: "1",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 2,
    serialNo: "4.2",
    name: "Understanding the needs and expectations of interested parties",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 3,
    serialNo: "4.3",
    name: "Determining the scope of the quality management system",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 4,
    serialNo: "4.4",
    name: "Quality management system and its processes",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 5,
    serialNo: "5.1",
    name: "Leadership & commitment",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 6,
    serialNo: "5.2",
    name: "Quality policy",
    initialVisit: {
      nci: "",
      ofi: "1",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 7,
    serialNo: "5.3",
    name: "Organisational roles, responsibilities and authorities",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 8,
    serialNo: "6.1",
    name: "Actions to address risks & opportunities",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 9,
    serialNo: "6.2",
    name: "Quality objectives and planning to achieve them",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 10,
    serialNo: "6.3",
    name: "Planning of changes",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 11,
    serialNo: "7.1",
    name: "Resources – General, people, infrastructure, environment",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 12,
    serialNo: "7.1.5",
    name: "Monitoring and measuring resources",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 13,
    serialNo: "7.1.6",
    name: "Organisational knowledge",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 14,
    serialNo: "7.2",
    name: "Competence",
    initialVisit: {
      nci: "",
      ofi: "1",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 15,
    serialNo: "7.3",
    name: "Awareness",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 16,
    serialNo: "7.4",
    name: "Communication",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 17,
    serialNo: "7.5",
    name: "Documented information",
    initialVisit: {
      nci: "1",
      ofi: "1",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 18,
    serialNo: "8.1",
    name: "Operational planning and control",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 19,
    serialNo: "8.2",
    name: "Requirements for products and services",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 20,
    serialNo: "8.3",
    name: "Design and development of products and services",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 21,
    serialNo: "8.4",
    name: "Control of externally provided processes, products and services",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 22,
    serialNo: "8.5",
    name: "Production and service provision",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 23,
    serialNo: "8.6",
    name: "Release of products and services",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 24,
    serialNo: "8.7",
    name: "Control of nonconforming outputs",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 25,
    serialNo: "9.1",
    name: "Monitoring, measurement, analysis and evaluation",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 26,
    serialNo: "9.2",
    name: "Internal Audit",
    initialVisit: {
      nci: "1",
      ofi: "1",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 27,
    serialNo: "9.3",
    name: "Management Review",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 28,
    serialNo: "10",
    name: "Improvement",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 29,
    serialNo: "10.2",
    name: "Nonconformity and corrective action",
    initialVisit: {
      nci: "1",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
  {
    id: 30,
    serialNo: "10.2",
    name: "Continual improvement",
    initialVisit: {
      nci: "",
      ofi: "",
    },
    sv1: {
      nci: "",
      ofi: "",
    },
    sv2: {
      nci: "",
      ofi: "",
    },
    recert: {
      nci: "",
      ofi: "",
    },
  },
];

const PagethirtyTwo = (props) => {
  const renderSameField = (itemmmm, indexxx, arrLength, bgColor, width) => {
    return (
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            fontSize: "8px",
            fontWeight: "900",
            borderRight: "1px solid black",
            borderBottom: 1,
            // arrLength - 1 !== indexxx ? "1px solid black" : "none",
            paddingLeft: "6px",
            paddingVertical: "3px",
            width: "6%",
          }}
        >
          {itemmmm.sub_clause_number}
        </Text>
        <Text
          style={{
            fontSize: "8px",
            fontWeight: "900",
            width: width,
            paddingHorizontal: "10px",
            paddingVertical: "3px",
            borderBottom: 1,
            // arrLength - 1 !== indexxx ? "1px solid black" : "none",
            borderRight: "1px solid black",
            backgroundColor: bgColor ? "#f2f2f2" : "transparent",
          }}
        >
          {itemmmm.sub_clause_title}
        </Text>
        <View
          style={{
            flexDirection: "row",
            flex: 1,
          }}
        >
          <View
            style={{
              borderBottom: 1,
              // arrLength - 1 !== indexxx ? "1px solid black" : "none",
              borderRight: "1px solid black",
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                borderRight: "1px solid black",
                flex: 1,
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              {itemmmm.ncs}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                flex: 1,
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              {itemmmm.ofis}
            </Text>
          </View>
          <View
            style={{
              borderBottom: 1,
              // arrLength - 1 !== indexxx ? "1px solid black" : "none",
              borderRight: "1px solid black",
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                borderRight: "1px solid black",
                flex: 1,
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              {itemmmm.ncs}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                flex: 1,
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              {itemmmm.ofis}
            </Text>
          </View>
          <View
            style={{
              borderBottom: 1,
              // arrLength - 1 !== indexxx ? "1px solid black" : "none",
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                borderRight: "1px solid black",
                flex: 1,
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              {itemmmm.ncs}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "900",
                flex: 1,
                textAlign: "center",
                paddingTop: "4px",
              }}
            >
              {itemmmm.ofis}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Page size={"A4"}>
      <PDFHeaderFooter>
        <View
          style={{
            borderTop: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            width: "90%",
            alignSelf: "center",
            marginTop: "10px",
          }}
        >
          <View
            style={{ flexDirection: "row", borderBottom: "1px solid black" }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "43.1%",
                borderRight: "1px solid black",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "900",
                  paddingLeft: "10px",
                  paddingVertical: "3px",
                }}
              >
                {props.standard} {"\n"}Nonconformities and OFIs raised {"\n"}
                over the cycle.{" "}
                <Text style={{ fontSize: "8px" }}>
                  Key: (M) Major (m) minor
                </Text>
              </Text>
            </View>
            <View style={{ flexDirection: "row", width: "60%" }}>
              <View
                style={{
                  borderRight: "1px solid black",
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    padding: "4px",
                  }}
                >
                  SV1 {"\n"} July 2024
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      paddingVertical: "4px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                      flex: 1,
                    }}
                  >
                    NCs
                  </Text>
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      textAlign: "center",
                      paddingVertical: "4px",
                      flex: 1,
                    }}
                  >
                    OFIs
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  borderRight: "1px solid black",
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    padding: "4px",
                  }}
                >
                  SV2 {"\n"} July 2025
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      paddingVertical: "4px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                      flex: 1,
                    }}
                  >
                    NCs
                  </Text>
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      textAlign: "center",
                      paddingVertical: "4px",
                      flex: 1,
                    }}
                  >
                    OFIs
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "8px",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid black",
                    padding: "4px",
                  }}
                >
                  ReCert {"\n"} July 2026
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      paddingVertical: "4px",
                      borderRight: "1px solid black",
                      textAlign: "center",
                      flex: 1,
                    }}
                  >
                    NCs
                  </Text>
                  <Text
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      textAlign: "center",
                      paddingVertical: "4px",
                      flex: 1,
                    }}
                  >
                    OFIs
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {props.subCluases.map((itemmmm, index) => {
            return renderSameField(itemmmm, index, 2, false, "35.8%");
          })}
        </View>
      </PDFHeaderFooter>
    </Page>
  );
};

export default PagethirtyTwo;
